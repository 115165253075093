import { green,teal, orange , red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const darkTheme =  createTheme({
  palette: {
    background: { default: '#333' },
    error: {  main: red[700] },
    mode: 'light',
    primary: { main: teal[800], light: teal[300], contrastText: '#fff' },
    secondary: { main: orange[500], light: orange[300], contrastText: '#333' },
    success:{main: green[700]},
  }
});
export const lightTheme =  createTheme({
  palette: {
    background: { default: '#ccc' },
    error: { main: red[700] },
    mode: 'light',
    primary: { main: orange[800]  , contrastText: '#fff'},
    secondary: { main: teal[500]  , contrastText: '#333' },
    success:{main: green[700]},

  }
});
