import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import { darkTheme as theme } from '../configs/theme';
import DataTable from '../features/DataTable/DataTable';
import HttpMethod from '../constants/httpMethod.enum';
import { apiConfig, loginAPIRequest } from '../configs/auth/authConfig';
import React, { FormEvent, ReactElement, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { Button, Container, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Slide, TextField, useMediaQuery, useTheme } from '@mui/material';
import { StyledForm, UnAuthenticatedDiv, WrappedDiv } from './styles/commonPageStyles'
import { SignInButton } from '../features/SignInButton/SignInButton';
import SectionTitle from '../features/SectionTitle/SectionTitle';

export function Home() {
  const [reportId, setReportId] = useState('');
  const [company, setCompany] = useState('');
  const [contractorName, setContractorName] = useState('');
  const [reportDate, setReportDate] = useState('');
  const [status, setStatus] = useState('');

  const initFilter = {
    'reportId': '',
    'company': '',
    'contractorName': '',
    'reportDate': '',
    'status': ''
  }

  const [filter, setFilter] = useState(initFilter);

  const dateFormatter = new Intl.DateTimeFormat('en-US');
  const formRef = useRef<HTMLFormElement>(null);
  const dataColumns = [
    {
      field: 'action', headerName: '', width: 100, align: 'center', sortable: false, renderCell: (params) => {

        if (params.row) {
          return (
            <Link target='_self' title='Open Contractor Report' to={'/contractorReports/' + params.row['hseContractorReportId']} style={{ textDecoration: 'none', color: `${theme.palette.primary.light}` }}>
              <ExitToAppIcon />
            </Link>
          );
        }
      }
    },
    { field: 'hseContractorReportId', flex: 1, headerName: 'Report ID', readonly: true },
    { field: 'company', headerName: 'Company', flex: 1, readonly: true },
    { field: 'contractorName', headerName: 'Contractor Name', flex: 1, readonly: true },
    { field: 'reportDate', headerName: 'Report Date', flex: 1, readonly: true },
    {
      field: 'submittedDate', headerName: 'Submission Date', flex: 1, readonly: true, valueFormatter: (params) => {
        return (params && !isNaN(Date.parse(params)) ? dateFormatter.format(Date.parse(params)) : '');
      }
    },
    { field: 'scaningStatus', headerName: 'Scanning Status', flex: 1, readonly: true },
    { field: 'status', headerName: 'Status', flex: 1, readonly: true },

  ] as Array<GridColDef>;

  const pageOptions = [10, 20, 30, 50, 100];

  const dataFetchUrl = apiConfig.apiEndPoint + '/HseContractorReports';

  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getColumnVisibility = useMemo((): GridColumnVisibilityModel => {
    return {
      'company': onlySmallScreen ? false : true,
      'contractorName': onlySmallScreen ? false : true,
      'reportDate': onlySmallScreen ? false : true,
      'submittedDate': onlyMediumScreen ? false : true,
      'scaningStatus': onlyMediumScreen ? false : true,
    } as GridColumnVisibilityModel
  }, [theme.breakpoints])

  const handleClickReset = () => {

    let newFilterModel = initFilter;
    setReportId('');
    setCompany('');
    setContractorName('');
    setReportDate('');
    setStatus('');
    setFilter(newFilterModel);

  }

  const handleClickSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let newFilterModel = initFilter;
    newFilterModel.reportId = reportId.trim();
    newFilterModel.company = company.trim();
    newFilterModel.contractorName = contractorName.trim();
    newFilterModel.reportDate = reportDate.trim();
    newFilterModel.status = status.trim();
    setFilter(newFilterModel);

  }

  const reportDateDropDownValues = (): any => {
    let array: Array<string> = [];
    const startYear = 2024;
    const endYear = 2026;
    const checkMonth = 8;
    const startMonth = 1;
    const endMonth = 12

    for (let i = startYear; i <= endYear; i++) {
      for (let j = startMonth; j <= endMonth; j++) {
        if (i == startYear && j < checkMonth) {
          //skip do nothing
        }
        else {
          array.push(('0' + j).slice(-2) + '-' + i);
        }

      }
    }

    return array;
  }


  return (
    <>
      <AuthenticatedTemplate>


        <StyledForm ref={formRef} onSubmit={(e) => { handleClickSubmit(e) }} >
          <SectionTitle title={'Search'}></SectionTitle>
          <Grid spacing={2} sx={{}}>
            <Grid item sm={12} md={4}>
              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='reportId'>Report Id:</InputLabel>
              </div>
              <div className={'homeSearchInput'}>
                <TextField id='reportId' variant='outlined' size='small' fullWidth value={reportId} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setReportId(event.target.value) }} />
              </div>
            </Grid>


            <Grid item sm={12} md={4}>
              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='company'>Company:</InputLabel>
              </div>
              <div className={'homeSearchInput'}>
                <TextField id='company' variant='outlined' size='small' fullWidth value={company} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setCompany(event.target.value) }} />
              </div>
            </Grid>
            <Grid item sm={12} md={4}>
              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='contractorName'>Contractor Name:</InputLabel>
              </div>
              <div className={'homeSearchInput'}>
                <TextField id='contractorName' variant='outlined' size='small' fullWidth value={contractorName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setContractorName(event.target.value) }} />
              </div>
            </Grid>
            <Grid item sm={12} md={4}>

              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='reportDate'>Report Date:</InputLabel>
              </div>

              <div className={'homeSearchInput'}>
                {/* <TextField id='status' variant='outlined' size='small' fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setStatus(event.target.value) }} /> */}
                <Select id='reportDate' variant='outlined' size='small' fullWidth value={reportDate} onChange={(event: SelectChangeEvent) => {
                  setReportDate(event.target.value);
                  setTimeout(() => {
                    formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
                  }, 500);
                }}>
                  <MenuItem value=''></MenuItem>
                  {reportDateDropDownValues().map((reportDateValue: string) => {
                    return (
                      <MenuItem value={reportDateValue}>{reportDateValue}</MenuItem>
                    )
                  })
                  }
                </Select>
              </div>
            </Grid>
            <Grid item sm={12} md={4}>

              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='status'>Status:</InputLabel>
              </div>

              <div className={'homeSearchInput'}>
                {/* <TextField id='status' variant='outlined' size='small' fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setStatus(event.target.value) }} /> */}
                <Select id='status' variant='outlined' size='small' fullWidth value={status} onChange={(event: SelectChangeEvent) => {
                  setStatus(event.target.value);
                  setTimeout(() => {
                    formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
                  }, 500);

                }}>
                  <MenuItem value=''></MenuItem>
                  <MenuItem value='Received'>Received</MenuItem>
                  <MenuItem value='Approved'>Approved</MenuItem>
                  <MenuItem value='Rejected'>Rejected</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid id='FormFooter' item xs={12}>
              <Button id='Submit' type='submit' className='primary'><SearchIcon />Search</Button>
              <Button id='Reset' className='secondary' onClick={handleClickReset}><RestartAltIcon />Reset</Button>
            </Grid>

          </Grid>

        </StyledForm>
        <div>&nbsp;</div>
        <Slide direction='up' in={true} timeout={1000}>


          <WrappedDiv>
            <SectionTitle title={'Submitted Reports'}></SectionTitle>
            <DataTable fetchUrl={dataFetchUrl} method={HttpMethod.POST} columns={dataColumns} pageSizeOptions={pageOptions} sortOptions={[{ field: 'submittedDate', sort: 'desc' }]} multipleRowSelection={false} filterOptions={filter} authRequest={loginAPIRequest} columnVisibilityModel={getColumnVisibility} />
          </WrappedDiv>

        </Slide>
      </AuthenticatedTemplate >

      <UnauthenticatedTemplate>
        <UnAuthenticatedDiv>
          <Typography variant='h4' align='center' sx={{ color: theme.palette.primary.light }}>
            Please sign-in to start using the application.
          </Typography>
          <br />
          <br />
          <SignInButton />
        </UnAuthenticatedDiv>
      </UnauthenticatedTemplate>

    </>
  );
}