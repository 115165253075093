/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { ErrorComponent } from '../features/ErrorComponent/ErrorComponent';
import { InteractionType } from '@azure/msal-browser';
import { Loading } from '../features/Loading/Loading';
import { loginRequest } from '../configs/auth/authConfig';

export function ErrorPage() {
  const authRequest = {
    ...loginRequest,
  };
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
    </MsalAuthenticationTemplate>
  );
}