import { Box, Theme, Typography, makeStyles } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { darkTheme as theme } from '../../configs/theme';
import { StyledTabContainer } from './styles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  
export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <StyledTabContainer
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        style={{overflowY:'auto', paddingBottom:'10px'}}
        {...other}
      >
        {value === index && (
          <Box p={1} >
            <Box>{children}</Box>
          </Box>
        )}
      </StyledTabContainer>
    );
  }
  
  function a11yProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }