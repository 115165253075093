import { LogLevel } from '@azure/msal-browser';

const environment = process.env.NODE_ENV === "production" ? "PROD" : "DEV";
const authority = environment === "PROD" ? process.env.REACT_APP_PROD_TENANTID : process.env.REACT_APP_DEV_TENANTID;
const clientId = environment === "PROD" ? process.env.REACT_APP_PROD_CLIENTID : process.env.REACT_APP_DEV_CLIENTID;
const redirectUrl = environment === "PROD" ? process.env.REACT_APP_PROD_REDIRECT_URL : process.env.REACT_APP_DEV_REDIRECT_URL;
const graphResource = environment === "PROD" ? process.env.REACT_APP_PROD_GRAPH_RESOURCE : process.env.REACT_APP_DEV_GRAPH_RESOURCE;
const apiResource = environment === "PROD" ? process.env.REACT_APP_PROD_API_RESOURCE : process.env.REACT_APP_DEV_API_RESOURCE;

export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${authority}/`,//https://login.microsoftonline.com/94b7e2b0-4207-4980-82dc-cd74f8dd1d39/',
    clientId: `${clientId}`,//'22ff9733-b6bd-4d04-96cd-380c09e74481',
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: '/',
    redirectUri: `${redirectUrl}`, //'https://localhost:3000/sign-oidc',
    clientCapabilities: ["CP1"] // this lets the resource owner know that this client is capable of handling claims challenge.

  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};


// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: `${graphResource}/v1.0/me`,
};

// Add here the endpoints for MS Graph API services you would like to use.
export const apiConfig = {
  apiEndPoint: `${apiResource}/api`,
  //'http://localhost:5000/api', //'https://qcaz-hsecr-dev-webapp-contractorreporting.azurewebsites.net/api',

};


export const loginRequest = {
  scopes: ['User.Read',
  ],
  apiResource:`${graphResource}`
};  

export const loginAPIRequest = {
  scopes: [
    'https://contractor-report-test.noc.qa/app-corp-sso-sustainabilityAPI/Sustainability-ReadWrite-Scope',//'https://contractor-report-test.noc.qa/app-corp-sso-sustainabilityAPI/Sustainability-ReadWrite-Scope'
  ],
  apiResource:`${apiResource}`,
};
