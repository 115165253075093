import { useState } from 'react';
import { InputLabel, Switch, SwitchPropsColorOverrides } from '@mui/material';


export interface ComparisonLabelsProps {
    label?: string,
    currentLabel?: string,
    comparisonLabel?: string
}

export const ComparisonLabelshData = (props: ComparisonLabelsProps) => {


    return (
        <>
            <div className={'reportLabel1'}>
                <InputLabel>{props.label}</InputLabel>
            </div>
            <div className={'reportDataLabel1'}>
                <InputLabel sx={{ alignSelf: 'center' }}>{props.comparisonLabel}</InputLabel>
            </div>
            <div className={'reportDataLabel2'}>
                <InputLabel sx={{ alignSelf: 'center' }}>{props.currentLabel}</InputLabel>
            </div>
        </>
    );
};



export function ComparisonLabels(props: ComparisonLabelsProps) {


    return (<ComparisonLabelshData label={props.label} comparisonLabel={props.comparisonLabel} currentLabel={props.currentLabel} ></ComparisonLabelshData>);



}

export default ComparisonLabels;