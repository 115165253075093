
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

import { darkTheme as theme } from '../../configs/theme';

export type GraphData = {
  displayName: string;
  jobTitle: string;
  mail: string;
  photo: string;
};

export const UserData: React.FC<{ graphData: GraphData }> = ({ graphData }) => {
  return (
    <List className="userData"  sx={{ backgroundColor: 'transparent', minWidth:'210px' }}>
      <NameListItem name={graphData.displayName} photo={graphData.photo} jobTitle={graphData.jobTitle} mail={graphData.mail} />

    </List>
  );
};

const NameListItem: React.FC<{ name: string, photo: string, jobTitle: string, mail: string }> = ({ name, photo, jobTitle, mail }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar src={photo}>
        <PersonIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={name} secondary={jobTitle } color={theme.palette.common.white} />
  </ListItem>
);

