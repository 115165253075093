import { Box } from "@mui/material"
import { SectionTitleBox } from "./styles";

interface SectionTitleProps{
    title:string
}
const SectionTitle=(props:SectionTitleProps) =>{
    return (
        <SectionTitleBox sx={{}}>
            {props.title}
        </SectionTitleBox>
    )
}

export default SectionTitle;