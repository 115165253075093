enum RouteConstants {
  HOME = '/',
  CONTRACTORREPORT = 'contractorReports/:id',
  CONTRACTORHISTORYREPORT = 'contractorReports/history/:id',
  PROFILE = 'profile',
  VENDORS = 'vendors',
  VENDORDETAILS = 'vendors/:id',
  NOTFOUND = '*'
}

export default RouteConstants;
