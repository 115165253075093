import { useState } from 'react';
import { InputLabel, Switch, SwitchPropsColorOverrides } from '@mui/material';


export interface SwitchProps {
    label: string,
    checked: boolean | undefined,
    color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
}

export const SwitchData = (props: SwitchProps) => {
    const [value, setValue] = useState<number | string | null>(null);


    return (
        <>
            <div className={'reportLabel'}>
                <InputLabel>{props.label}</InputLabel>
            </div>
            <div className={'reportDataLabel'}>
            <Switch color={props.color} checked={props.checked} />
            </div>
        </>
    );
};



export function SwitchElement(props: SwitchProps) {


    return (<SwitchData label={props.label} checked={props.checked} color={props.color} ></SwitchData>);



}

export default SwitchElement;