import { Grid } from '@mui/material';
import NavBar from '../features/NavBar/NavBar';
import SideBar from '../features/SideBar/SideBar';
import { Outlet } from 'react-router-dom';
import { StyledMainContent } from './layoutStyles';
import { useIsAuthenticated } from '@azure/msal-react';
import { ThemeConsumer } from 'styled-components';

import { darkTheme as theme } from '../configs/theme';

type Props = {
  children?: React.ReactNode;
};

const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <StyledMainContent>
      <Grid container justifyContent="center">
        <div style={{ display: 'flex', width: '100%' }}>
          {useIsAuthenticated() && <SideBar />}
          <div style={{ width: '100%' }}>
            <NavBar />

            <div style={{ backgroundColor: theme.palette.common.white, width: '100%', paddingLeft: '60px' }}>
              {children}
              <Outlet />
            </div>
          </div>
        </div>
      </Grid >
    </StyledMainContent>
  );
}



export default PageLayout;
