import styled from 'styled-components';
import {darkTheme as theme} from '../../configs/theme'

export const StyledDataTable = styled.div `
color: ${theme.palette.common.black};
font-family: 'Calibri' !important;

.MuiDataGrid-virtualScroller{
  //border-color:${theme.palette.primary.light}!important;
  color: ${theme.palette.common.black};
  font-family: 'Calibri' !important;
}
.MuiDataGrid-columnHeaders [role=row], .MuiDataGrid-container--bottom [role=row] {
  color: ${theme.palette.primary.light};
  background-color: ${theme.palette.common.white}!important;
  background: ${theme.palette.common.white}!important;
  font-family: 'Calibri' !important;
}

.MuiTablePagination-root, .MuiTablePagination-root svg, .MuiDataGrid-selectedRowCount, .MuiTablePagination-select {
  color:${theme.palette.primary.light}!important;
  -webkit-text-fill-color:${theme.palette.primary.light}!important;
  font-family: 'Calibri' !important;
}

.MuiDataGrid-columnHeaders [role=row], .MuiDataGrid-container--bottom [role=row], .MuiDataGrid-withBorderColor {
  //border-color:${theme.palette.primary.light}!important;
.MuiSvgIcon-root{
  margin-top:0px;
}
.MuiDataGrid-main .MuiDataGrid-virtualScrollerContent .MuiSvgIcon-root{
  margin-top:12px;
}
.odd{
  background-color: #f5f5f5; //rgb(77, 182, 172,0.3);//#e0e0e0;
 
}
.MuiDataGrid-row:hover{
  background-color: rgb(77, 182, 172,0.5)!important;
}
.MuiIconButton-root{
  &:hover{
    svg {
      color:${theme.palette.common.white}!important;
    }
  }
}

.MuiTablePagination-actions{
  button{
    height: 32px;
    width: 32px;

    svg{
      height: 28px;
    width: 28px;
    }
  }
}
`;
