import styled from 'styled-components';
import {darkTheme as theme} from '../../configs/theme'

export const FileAttachmentButton = styled.button `
color: ${theme.palette.primary.light}!important;
border-color:${theme.palette.primary.light}!important;
background-color: ${theme.palette.common.white}!important;
margin: 0px;
padding: 6px 10px !important;
display:flex;
align-items:center;
justify-content:center;
font-family: "Roboto","Helvetica","Arial",sans-serif;
font-weight: bold;

&:hover{
  color:${theme.palette.common.white}!important;
  background-color:  ${theme.palette.primary.light}!important;
  cursor: pointer;
}
`;
