import React, { useEffect, useState } from 'react';
import { darkTheme as theme } from '../../configs/theme';
import { Box, Button, Grid, InputLabel, Modal, Slide, TextField, Typography } from '@mui/material';
import { deepClone } from '@mui/x-data-grid/internals';
import { apiConfig, loginAPIRequest } from '../../configs/auth/authConfig';
import HttpMethod from '../../constants/httpMethod.enum';
import { ApiCall } from '../../services/ApiCall';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { StyledModalContainer } from './styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

export interface StatusActionProps {
    reportId: string,
    scaningStatus: string | null,
    status: string | null,
    comment: string | null,
    readOnly: boolean,
}

export const StatusData = (props: StatusActionProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<StatusActionProps>(props);
    const [open, setOpen] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>(''); //= 'CONTRACTOR REPORT';
    const [modalDescription, setModalDescription] = useState<string>('');
    const [modalType, setModalType] = useState<string>('');
    const postUrl: string = apiConfig.apiEndPoint + '/HseContractorReports/Feedback';

    const MySwal = withReactContent(Swal);

    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newData = deepClone(data);
        newData.comment = event.currentTarget.value;
        setData(newData);
    }

    const handleClose = () => {
        setOpen(false);
        if (modalType === 'Success') {
            navigate('/contractorReports/' + data.reportId, { replace: true });
        }

    };

    useEffect(() => {
        if (open) {
            const textcolor = (modalType === 'success') ? 'green' : 'red';
            MySwal.fire({
                title: modalTitle,
                html: <p style={{ color: textcolor }}>{modalDescription}</p>,
                iconHtml: (modalType === 'success') ? <CheckCircleOutlineIcon style={{ color: textcolor, fontWeight: 'bold' }} ></CheckCircleOutlineIcon> : <ReportProblemIcon style={{ color: textcolor, fontWeight: 'bold' }} ></ReportProblemIcon>,
            }).then(() => {
                (modalType === 'success') ? handleClose() : setOpen(false);
            });
        }


    }, [open]);




    const handleSubmit = (newData: StatusActionProps) => {

        instance.acquireTokenSilent({
            ...loginAPIRequest,
            account,
        }).then((response: any) => {
            if (response.accessToken) {
                ApiCall(postUrl, HttpMethod.POST, null, newData, response.accessToken)
                    .then((response) => {
                        if (response && response) {
                            setModalTitle('Success');
                            setModalDescription('Review submitted sucessfully');
                            setModalType('success');
                            setOpen(true);




                        }


                    })
                    .catch((error) => { console.error(error); })
            }
            else {
                instance.loginPopup(loginAPIRequest);
            }
        }).catch((error: any) => { console.log(error); });

    }
    const handleClickApprove = (event: any) => {
        const newData = deepClone(data);
        newData.status = 'Approved';
        if (newData.comment && newData.comment !== '') {
            setData(newData);
            handleSubmit(newData);
        }
        else {
            setModalTitle('Missing Information!');
            setModalDescription('Reviewer comments are required!');
            setModalType('error');
            setOpen(true);


        };
    }

    const handleClickReject = (event: any) => {
        const newData = deepClone(data);
        newData.status = 'Rejected';
        if (newData.comment && newData.comment !== '') {
            setData(newData);
            handleSubmit(newData);
        }
        else {
            setModalTitle('Missing Information!');
            setModalDescription('Reviewer comments are required!');
            setModalType('error');
            setOpen(true);


        }
    };


    return (
        <>
            <div className={'sectionHeader'}>
                <InputLabel>Reviewer Section</InputLabel>
            </div>

            {data &&
                <>
                    <Grid container marginLeft={0} marginTop={0}>

                        { //data.readOnly || (data.status && data.status !== '') &&
                            <>
                                <Grid item xs={12}>
                                    <div className={'reportLabel4'}>
                                        <InputLabel>Scanning Status:</InputLabel>
                                    </div>
                                    <div className={'reportDataLabel4'}>
                                        <TextField fullWidth variant='outlined' size='small' value={data.scaningStatus??''} InputProps={{
                                            readOnly: true
                                        }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={'reportLabel4'}>
                                        <InputLabel>Status:</InputLabel>
                                    </div>
                                    <div className={'reportDataLabel4'}>
                                        <TextField fullWidth variant='outlined' size='small' value={data.status??''} InputProps={{
                                            readOnly: true
                                        }} />
                                    </div>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <div className={'reportLabel4'}>
                                <InputLabel>Comments:</InputLabel>
                            </div>
                            <div className={'reportDataLabel4'}>
                                <TextField fullWidth multiline minRows={3} variant='outlined' size='small' value={data.comment??''} onChange={handleChangeComment} InputProps={{
                                    readOnly: (!data.readOnly && data.status !== 'Approved' && data.status !== 'Rejected') ? false : true
                                }} />
                            </div>
                        </Grid>
                        {(!data.readOnly && data.status !== 'Approved' && data.status !== 'Rejected') &&
                            <Grid id='ActionFooter' item xs={12}>
                                <Button id='Approve' className='primary' onClick={handleClickApprove} ><CheckIcon />Approve</Button>
                                <Button id='Reject' className='secondary' onClick={handleClickReject} ><ClearIcon />Reject</Button>
                            </Grid>
                        }

                    </Grid>

                    {/*  <StyledModalContainer style={{

                        }}>
                            <Modal
                                id='popupModal'
                                aria-labelledby='modalTitle'
                                aria-describedby='modalDescription'
                                open={open}
                                onClose={handleClose}
                                sx={{

                                }}>
                                <Slide direction='up' in={open} mountOnEnter unmountOnExit>
                                    <Box sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
                                        <Typography paddingLeft={1} paddingRight={1} variant='h6' id='modalTitle' sx={{}}>
                                            CONTRACTOR REPORT
                                        </Typography>
                                        <Typography paddingTop={2} paddingBottom={2} paddingLeft={1} paddingRight={1} variant='body1' id='modalDescription' sx={{ color: (modalType === 'Success' ? theme.palette.primary.light : 'red') }}>
                                            {modalDescription}
                                        </Typography>
                                        <div id='modalFooter' style={{}}>
                                            <Button id='Ok' className='primary' onClick={handleClose}>OK</Button>
                                        </div>

                                    </Box>
                                </Slide>
                            </Modal>
                        </StyledModalContainer> */}
                </>
            }

        </>
    );
};



export function StatusAction(props: StatusActionProps) {


    return (<StatusData reportId={props.reportId} status={props.status} scaningStatus={props.scaningStatus} comment={props.comment} readOnly={props.readOnly}></StatusData>);



}

export default StatusAction;