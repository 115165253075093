import React, { useState } from 'react';
import { darkTheme as theme } from '../../configs/theme';
import { Box, Button, Grid, InputLabel, Modal, Slide, TextField, Typography } from '@mui/material';
import { deepClone } from '@mui/x-data-grid/internals';
import { apiConfig, loginAPIRequest } from '../../configs/auth/authConfig';
import HttpMethod from '../../constants/httpMethod.enum';
import { ApiFileDownloadCall } from '../../services/ApiCall';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FileAttachmentButton } from './styles';

export interface FileAttachmentProps {
    filePath: string,
    fileName: string,
}

export const FileAttachmentDownload = (props: FileAttachmentProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<FileAttachmentProps>(props);
    const postUrl: string = apiConfig.apiEndPoint + '/HseContractorReports/File';
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }


    const handleFileDownload = (event: any) => {
        instance.acquireTokenSilent({
            ...loginAPIRequest,
            account,
        }).then((response: any) => {
            if (response.accessToken) {
                ApiFileDownloadCall(postUrl, data, response.accessToken)
                    .then((response) => {
                        const url = window.URL.createObjectURL(response as Blob);
                        const newFileName = `${data.fileName.substring(0, data.fileName.lastIndexOf('.')) + "-" + new Date().toISOString() + data.fileName.substring(data.fileName.lastIndexOf('.'))}`;
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', newFileName);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode?.removeChild(link);

                    })
                    .catch((error) => { console.error(error); })
            }
            else {
                instance.loginPopup(loginAPIRequest);
            }
        }).catch((error: any) => { console.log(error); });
    }

    return (
        <>

            {data &&
                <p style={{ width: '50%', margin:'0px',padding:'0px',display:'flex',alignItems:'center',justifyContent:'end', }}>
                    <FileAttachmentButton id='DownloadFileAttachment' className='primary' onClick={handleFileDownload}>
                        <AttachFileIcon></AttachFileIcon>
                        {data.fileName}
                    </FileAttachmentButton>
                </p >

            }

        </>
    );
};



export function FileAttachment(props: FileAttachmentProps) {


    return (<FileAttachmentDownload filePath={props.filePath} fileName={props.fileName}></FileAttachmentDownload>);



}

export default FileAttachment