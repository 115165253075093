import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { darkTheme as theme } from '../../configs/theme';
import { loginRequest } from '../../configs/auth/authConfig';
import React from 'react';
import { PowerSettingsNew } from '@mui/icons-material';
import { StyledButtonContainer } from '../../layouts/layoutStyles';

export function SignInButton() {
  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogin = (loginType: string) => {
    setAnchorEl(null);

    if (loginType === 'popup') {
      instance.loginPopup(loginRequest);
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <StyledButtonContainer>
      <Button
        onClick={(event) => handleLogin('popup')}//setAnchorEl(event.currentTarget)}
        style={{ backgroundColor: theme.palette.common.white, color:theme.palette.primary.light, border:`1px solid ${theme.palette.primary.light}`}}
      >  <PowerSettingsNew />
        <span style={{paddingLeft:'8px'}}>Login</span>
      </Button>
      {/*   <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        keepMounted
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleLogin('popup')} key="loginPopup">
          Sign in using Popup
        </MenuItem>
        <MenuItem onClick={() => handleLogin('redirect')} key="loginRedirect">
          Sign in using Redirect
        </MenuItem>
      </Menu> */}
    </StyledButtonContainer>
  );
}
