import styled from 'styled-components';
import {darkTheme as theme} from '../../configs/theme'
import Box from '@mui/material/Box';

export const SectionTitleBox = styled(Box)`
background-color:${theme.palette.primary.light};
color:${theme.palette.common.white};
font-weight:bold;
display:flex;
justify-content: left;
align-items: center;
border-bottom: 1px solid light-grey;
min-height: 58px;
padding:5px;
`;


