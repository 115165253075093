import PageLayout from './layouts/PageLayout';
import { AuthProvider } from './auth/hooks/authProvider';
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { ErrorPage } from './pages/ErrorPage';
import { Profile } from './pages/Profile';
import RouteConstants from './constants/routes.enum';
import { Home } from './pages/Home';
import { ContractorReport } from './pages/ContractorReport';
import { GlobalStyle } from './layouts/globalStyles';
import { ContractorHistoryReport } from './pages/ContractorReportHistory';
import { Vendors } from './pages/Vendors';
import { VendorDetail } from './pages/VendorDetail';

function App() {
  const environment = process.env.NODE_ENV === "production" ? "PROD" : "DEV";
  const basepath = environment === "PROD" ? process.env.REACT_APP_PROD_BASEPATH : process.env.REACT_APP_DEV_BASEPATH;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={RouteConstants.HOME} element={<PageLayout />}>
          <Route path={RouteConstants.CONTRACTORHISTORYREPORT} element={<ContractorHistoryReport />} />
          <Route path={RouteConstants.CONTRACTORREPORT} element={<ContractorReport />} />
          <Route path={RouteConstants.PROFILE} element={<Profile />} />
          <Route path={RouteConstants.VENDORS} element={<Vendors />} />
          <Route path={RouteConstants.VENDORDETAILS} element={<VendorDetail />} />
          <Route path={RouteConstants.NOTFOUND} element={<ErrorPage />} />
          <Route index element={<Home />} />
        </Route>
        <Route path={RouteConstants.NOTFOUND} element={<Navigate to={RouteConstants.HOME} replace />} />
      </>
    ),{ basename:basepath}
  );


  return (
    <AuthProvider>
      <GlobalStyle></GlobalStyle>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
