import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import { darkTheme as theme } from '../configs/theme';
import DataTable from '../features/DataTable/DataTable';
import HttpMethod from '../constants/httpMethod.enum';
import { apiConfig, loginAPIRequest } from '../configs/auth/authConfig';
import React, { FormEvent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { Button, Grid, InputLabel, Slide, Switch, TextField, useMediaQuery } from '@mui/material';
import { StyledForm, WrappedDiv } from './styles/commonPageStyles'
import SectionTitle from '../features/SectionTitle/SectionTitle';

export function Vendors() {
  const [vendorName, setVendorName] = useState('');
  const [department, setDepartment] = useState('');
  const [contractOwner, setContractOwner] = useState('');
  const [contractId, setContractId] = useState('');

  const initFilter = {
    'contractId': '',
    'vendorName': '',
    'contractOwner': '',
    'department': '',

  }

  const [filter, setFilter] = useState(initFilter);

  const dateFormatter = new Intl.DateTimeFormat('en-US');
  const dataColumns = [
    {
      field: 'action', headerName: '', width: 100, sortable: false, renderCell: (params) => {

        if (params.row) {
          return (
            <Link target='_self' title='Open Contract Details' to={'/vendors/' + params.row['contractId']} style={{ textDecoration: 'none', color: `${theme.palette.primary.light}` }}>
              <ExitToAppIcon />
            </Link>
          );
        }
      }
    },
    { field: 'contractId', flex: 1, headerName: 'Contract Number', readonly: true },
    { field: 'vendorName', headerName: 'Vendor', flex: 1, readonly: true },
    { field: 'contractOwner', headerName: 'Contract Owner', flex: 1, readonly: true },
    /* {
      field: 'submittedDate', headerName: 'Submitted Date', width: 250, readonly: true, valueFormatter: (params) => {
        return (params && !isNaN(Date.parse(params)) ? dateFormatter.format(Date.parse(params)) : '');
      }
    }, */
    { field: 'department', headerName: 'Department', flex: 1, readonly: true },
    {
      field: 'startDate', headerName: 'Start Date', flex: 1, readonly: true, valueFormatter: (params) => {

        const strDate: string = params;
        const date = strDate.length === 8 ? strDate.substring(0, 4) + '-' + strDate.substring(4, 6) + '-' + strDate.substring(6, 8) : params;
        return (date && !isNaN(Date.parse(date)) ? dateFormatter.format(Date.parse(date)) : '');

      }
    },
    {
      field: 'endDate', headerName: 'End Date', flex: 1, readonly: true, valueFormatter: (params) => {
        const strDate: string = params;
        const date = strDate.length === 8 ? strDate.substring(0, 4) + '-' + strDate.substring(4, 6) + '-' + strDate.substring(6, 8) : params;
        return (date && !isNaN(Date.parse(date)) ? dateFormatter.format(Date.parse(date)) : '');
      }
    },
    {
      field: 'isValid', headerName: 'Is Active', flex: 1, readonly: true, renderCell: (params) => {
        return (<Switch sx={{ '.MuiSwitch-switchBase.Mui-checked': theme.palette.primary.light }} checked={params.value === 'IsValid' || params.value.toUpperCase() === 'TRUE' ? true : false} readOnly />);
      }
    },

  ] as Array<GridColDef>;

  const pageOptions = [10, 20, 30, 50, 100];

  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getColumnVisibility = useMemo((): GridColumnVisibilityModel => {
    return {
      'vendorName': onlySmallScreen ? false : true,
      'contractOwner': onlySmallScreen ? false : true,
      'department': onlySmallScreen ? false : true,
      'startDate': onlyMediumScreen ? false : true,
      'endDate': onlyMediumScreen ? false : true,
    } as GridColumnVisibilityModel
  }, [onlySmallScreen, onlyMediumScreen])


  const dataFetchUrl = apiConfig.apiEndPoint + '/Contracts';



  const handleClickReset = () => {

    let newFilterModel = initFilter;
    setContractId('');
    setVendorName('');
    setContractOwner('');
    setDepartment('');
    setFilter(newFilterModel);

  }

  const handleClickSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let newFilterModel = initFilter;
    newFilterModel.contractId = contractId.trim();
    newFilterModel.vendorName = vendorName.trim();
    newFilterModel.contractOwner = contractOwner.trim();
    newFilterModel.department = department.trim();
    setFilter(newFilterModel);

  }


  return (
    <>
      <AuthenticatedTemplate>



        <StyledForm onSubmit={(e) => { handleClickSubmit(e) }}>
          <SectionTitle title={'Search'}></SectionTitle>
          <Grid container sx={{}}>
            <Grid item sm={12} md={6}>
              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='contractId'>Contract Number:</InputLabel>
              </div>
              <div className={'homeSearchInput'}>
                <TextField id='contractId' variant='outlined' size='small' fullWidth value={contractId} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setContractId(event.target.value) }} />
              </div>
            </Grid>


            <Grid item sm={12} md={6}>
              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='vendorName'>Vendor:</InputLabel>
              </div>
              <div className={'homeSearchInput'}>
                <TextField id='vendorName' variant='outlined' size='small' fullWidth value={vendorName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setVendorName(event.target.value) }} />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='contractOwner'>Contract Owner:</InputLabel>
              </div>
              <div className={'homeSearchInput'}>
                <TextField id='contractOwner' variant='outlined' size='small' fullWidth value={contractOwner} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setContractOwner(event.target.value) }} />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>

              <div className={'homeSearchLabel'}>
                <InputLabel htmlFor='department'>Department:</InputLabel>
              </div>

              <div className={'homeSearchInput'}>
                <TextField id='department' variant='outlined' size='small' fullWidth value={department} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setDepartment(event.target.value) }} />
              </div>
            </Grid>
            <Grid id='FormFooter' item xs={12}>
              <Button id='Submit' type="submit" className='primary'><SearchIcon />Search</Button>
              <Button id='Reset' className='secondary' onClick={handleClickReset}><RestartAltIcon />Reset</Button>
            </Grid>

          </Grid>

        </StyledForm>

        <div>&nbsp;</div>
        <Slide direction='up' in={true} timeout={500}>


          <WrappedDiv>
            <SectionTitle title={'SAP Data'}></SectionTitle>
            <DataTable fetchUrl={dataFetchUrl} method={HttpMethod.POST} columns={dataColumns} pageSizeOptions={pageOptions} sortOptions={[{ field: 'contractId', sort: 'desc' }]} multipleRowSelection={false} filterOptions={filter} authRequest={loginAPIRequest} columnVisibilityModel={getColumnVisibility} />
          </WrappedDiv>

        </Slide>
      </AuthenticatedTemplate >

      <UnauthenticatedTemplate>
        <Typography variant='h6' align='center' sx={{ color: theme.palette.primary.main }}>
          Please sign-in to see your profile information.
        </Typography>
      </UnauthenticatedTemplate>

    </>
  );
}