import { useIsAuthenticated, type MsalAuthenticationResult } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { darkTheme as theme } from '../../configs/theme';

export const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {

  return (
    useIsAuthenticated() ? (
      <Typography variant="h6" style={{ color: theme.palette.error.main }}>
        An Error Occurred: {error ? error.errorCode : 'unknown error'}
      </Typography>) : null);

};
