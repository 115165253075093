import styled, { keyframes } from 'styled-components';

import { darkTheme as theme } from '../../configs/theme';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(60px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-60px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
`;

export const ClosedSideBar = styled.header`
  max-width: 60px;
  width: 100%;
  height: 100%;
  /*border-radius: 0 12px 12px 0;*/
  
  box-shadow:0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);

  background: ${theme.palette.common.white};
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  svg {
    color: ${theme.palette.primary.light};
    background-color: ${theme.palette.common.white};
    &:hover, &.active {
      zoom:1.0;
    }
  }

  ul li {
    cursor: pointer;
  }

  /* Links principais do app */
  nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    
    > p {
      width: 100%;
      display:flex;
      height:40px;
      justify-content: center;
      align-items: center;
      > button {
        width: 30px;
        height: 30px;
        padding:0px;
        color: ${theme.palette.primary.light};
        background-color: ${theme.palette.common.white};
  
        &:hover, &.active {
          zoom:1.0;
          p {
         
            color: ${theme.palette.primary.light};
          }
          svg {
            path {
             
              color: ${theme.palette.primary.light};
            }
          }
        }
      }
  
      > button svg {
        width: 30px;
        height: 30px;
  
        color: ${theme.palette.primary.light};
      }
  
    
    }

    
    > img {
      width: 30px;
      height: 30px;
      margin-top: 16px;
      &:hover, &.active {
        zoom:1.0;
      }
    }

    ul {
      margin-top: 64px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0;

      a {
        width: 100%;
        padding: 16px 0;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${theme.palette.common.white};
        color: ${theme.palette.primary.light};
        transition: background 0.3s;

        &:hover, &.active {
          background: ${theme.palette.primary.light};
          color: ${theme.palette.common.white};
          zoom:1.0;
          p {
            color: ${theme.palette.common.white};
            background-color: transparent;
          }
          svg {
            color: ${theme.palette.common.white};
            background-color: transparent;
            opacity:1.0;
            path {
              color: ${theme.palette.common.white};
              background-color: transparent;
            }
          }
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    ul {
      margin-bottom: 16px;
      text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0;
      a {
        padding: 16px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background: ${theme.palette.common.white};
        color: ${theme.palette.primary.light};
        transition: color 0.3s;
        &:hover, &.active {
          background: ${theme.palette.primary.light};
          color: ${theme.palette.common.white};
          zoom:1.0;
          p {
            background: transparent;
            color: ${theme.palette.common.white};
          }
          svg {
            color: ${theme.palette.common.white};
            background-color: transparent;
            opacity:1.0;
            path {
            background: transparent;
            color: ${theme.palette.common.white};
          }
        }
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    span {
      padding: 16px 0;
      text-align: center;
     
      display: flex;
      align-items: center;
      justify-content: center;

      background: ${theme.palette.primary.light};
      width: 100%;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const OpenSideBar = styled.header`
  height: 100%;
  width: 100%;
  max-width: 240px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background:${theme.palette.common.white};
  
box-shadow:0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);

  display: flex;
  align-items: center;

  svg {
    color: ${theme.palette.primary.light};
    &:hover, &.active {
      zoom:1.0;
    }
  }

  section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    max-width: 240px;
    min-width: 240px;
    height: 100%;
   
    ul li {
      cursor: pointer;
    }

    /* Links principais do app */
    nav {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      > p { 
        width: 100%;
        direction: rtl;
        display:flex;
        height:40px;
        justify-content: right;
        align-items: center;
        animation: ${appearFromLeft} 0.4s;
        button {
          cursor: pointer;
          padding-right: 10px;

          &:hover, &.active {
            zoom:1.0;
            p {
              color: ${theme.palette.primary.light};
            }
            svg path {
              color: ${theme.palette.primary.light};
            }
          }

          svg {
            width: 30px;
            height: 30px;

            color: ${theme.palette.primary.light};
          }
        }
       
      }
     
      div {
        margin-top: 10px;
        display: flex;
        direction:ltr;
        align-items: center;
        justify-content: flex-start;
        padding-left: 12px;
        flex-direction: row;
        gap: 16px;

        img {
          width: 30px;
          height: 30px;
          
          &:hover, &.active {
            zoom:1.0;
          }  
        }

        p {
          color: ${theme.palette.primary.light};
          font-size: 12pt;
          font-weight: bold;
          animation: ${appearFromRight} 0.4s;
        }
      }

      ul {
        margin-top: 58px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: left;
        flex-direction: column;
        padding: 0;
        gap: 4px;
        a {
          color: ${theme.palette.primary.light};
          padding: 0px 16px;
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 16px;
          transition: color 0.3s;
          &:hover, &.active {
            zoom:1.0;
            background: ${theme.palette.primary.light};
            color: ${theme.palette.common.white};
            p {
              color: ${theme.palette.common.white};
            }
            svg path {
              color: ${theme.palette.common.white};
            }
          }

          p {
            animation: ${appearFromRight} 0.4s;
            font-size: 12pt;
            font-weight: bold;
            text-decoration:none;
            text-overflow: ellipsis;
          }

          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      ul {
        margin-bottom: 16px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: left;
        flex-direction: column;
        padding: 0;
        a {
          padding: 0px 16px;
          display: flex;
          align-items: center;
          color: #fcfcfc;
          gap: 16px;
          text-decoration: none;
          background: ${theme.palette.common.white};
          color: ${theme.palette.primary.light};
          transition: color 0.3s;
          &:hover, &.active {
            background: ${theme.palette.primary.light};
            color: ${theme.palette.common.white};
            zoom:1.0;
            p {
              background: transparent;
              color: ${theme.palette.common.white};
            }
            svg {
              color: ${theme.palette.common.white};
              background-color: transparent;
              opacity:1.0;
              path {
              background: transparent;
              color: ${theme.palette.common.white};
            }
          }
        }
          svg {
            width: 30px;
            height: 30px;
          }

          p {
            animation: ${appearFromRight} 0.4s;
            font-size: 12pt;
            
            font-weight: bold;
            text-decoration:none;
            text-overflow: ellipsis;
  
          }
        }
      }

      span {
        padding: 16px 0;
      
        background: ${theme.palette.common.white};
        width: 100%;

        display: flex;
        align-items: center;
        gap: 12px;

        p {
          text-decoration:none;
          text-overflow: ellipsis;
          color: ${theme.palette.primary.light};
          width: 70%;
          padding-right: 12px;
          white-space: nowrap;
          animation: ${appearFromRight} 0.4s;
          overflow: hidden;
          font-weight: bold;
        }

        img {
          margin-left: 14px;
          width: 30px;
          height: 30px;
         
        }
      }
    }
  }

  aside {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
`;
