
import HttpMethod from '../constants/httpMethod.enum';

export async function ApiCall(url: string, method: HttpMethod, headers?: any, data?: any, accessToken?: string) {

    if (!headers) {
        headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
    }
    if (accessToken) {
        headers.append('Authorization', `Bearer ${accessToken}`);
    }
    const optionsWoBody = {
        headers,
        method
    };
    const optionsWBody = {
        headers,
        method,
        body: JSON.stringify(data)
    }
    const options = method === HttpMethod.GET ? optionsWoBody : optionsWBody;

    return fetch(url, options)
        .then((response) => {
            return response.json()
                .then((responseJson) => {
                    let currentPage = 0;
                    let rowsCount = 0;
                    let stringJson: string | null = '';
                    if (response && responseJson) {
                        if (response.headers && response.headers.get('Pagination')) {
                            stringJson = response.headers.get('Pagination');
                            if (stringJson) {
                                rowsCount = JSON.parse(stringJson)?.totalItems as number;
                                currentPage = JSON.parse(stringJson)?.currentPage as number;
                                if (currentPage > 0) {
                                    currentPage -= 1;
                                }
                            }
                        }
                        return { paginationHeader: { "rowsCount": rowsCount, "currentPage": currentPage }, content: responseJson };
                    }
                    else {
                        return { paginationHeader: { "rowsCount": rowsCount, "currentPage": currentPage }, content: null };
                    }
                })
        })
        .catch((error) => console.log(error));
}
export async function ApiFileDownloadCall(url: string, data?: any, accessToken?: string) {


    const headers = new Headers();
    headers.append('Accept', 'text/plain');
    headers.append('Content-Type', 'application/json');

    if (accessToken) {
        headers.append('Authorization', `Bearer ${accessToken}`);
    }

    const options = {
        headers,
        method: HttpMethod.POST,
        body: JSON.stringify(data)
    }

    return fetch(url, options)
        .then((response) => {
            return response.blob();
        })
        .catch((error) => console.log(error));
}
