import { createGlobalStyle } from 'styled-components';
import { darkTheme as theme } from '../configs/theme';

export const GlobalStyle = createGlobalStyle`
  body,input,label,textarea,div,p,span,button,.MuiFormLabel-root,.MuiInputLabel-root,.MuiInputBase-root,.MuiTypography-root{
    
    font-size: 0.8rem;
    overflow-y: hidden;
    overflow-x: hidden;

    .MuiFormLabel-root,.MuiInputLabel-root,.MuiDataGrid-columnHeaderTitle {
      zoom:1.1;
      transition: zoom 1s !important;
      font-weight: bold !important;
    }
    .MuiInputLabel-root,.MuiInputBase-root,.MuiTypography-root,.MuiDataGrid-cell{
      font-weight: 200 !important;
    }
    h5,.MuiTypography-h5{
      font-size: 0.85rem;
      transition: font-size 1s !important;
    }
    h4,.MuiTypography-h4{
      font-size: 0.9rem;
      transition: font-size 1s !important;
    }
    h3,.MuiTypography-h3{
      font-size: 0.95rem;
      transition: font-size 1s !important;
    }
    h2,.MuiTypography-h2{
      font-size: 1.0rem;
      transition: font-size 1s !important;
    }
    h1,.MuiTypography-h1{
      font-size: 1.05rem;
      transition: font-size 1s !important;
    }
${theme.breakpoints.up('sm')} {

    font-size: 0.9rem !important;
    transition: font-size 1s !important;
    h5,.MuiTypography-h5{
      font-size: 0.95rem;
      transition: font-size 1s !important;
    }
    h4,.MuiTypography-h4{
      font-size: 1.0rem;
      transition: font-size 1s !important;
    }
    h3,.MuiTypography-h3{
      font-size: 1.05rem;
      transition: font-size 1s !important;
    }
    h2,.MuiTypography-h2{
      font-size: 1.1rem;
      transition: font-size 1s !important;
    }
    h1,.MuiTypography-h1{
      font-size: 1.15rem;
      transition: font-size 1s !important;
    }
}
${theme.breakpoints.up('md')} {
    font-size: 0.95rem !important;
    transition: font-size 1s !important;
    h5,.MuiTypography-h5{
      font-size: 1.0rem;
      transition: font-size 1s !important;
    }
    h4,.MuiTypography-h4{
      font-size: 1.05rem;
      transition: font-size 1s !important;
    }
    h3,.MuiTypography-h3{
      font-size: 1.1rem;
      transition: font-size 1s !important;
    }
    h2,.MuiTypography-h2{
      font-size: 1.15rem;
      transition: font-size 1s !important;
    }
    h1,.MuiTypography-h1{
      font-size: 1.2rem;
      transition: font-size 1s !important;
    }
    button {
      padding-top:2px !important;
      padding-bottom:2px !important;
    }
}
${theme.breakpoints.up('lg')} {
    font-size: 1.0rem !important;
    transition: font-size 1s !important;
    h5,.MuiTypography-h5{
      font-size: 1.05rem !important;
      transition: font-size 1s !important;
    }
    h4,.MuiTypography-h4{
      font-size: 1.1rem !important;
      transition: font-size 1s !important;
    }
    h3,.MuiTypography-h3{
      font-size: 1.15rem !important;
      transition: font-size 1s !important;
    }
    h2,.MuiTypography-h2{
      font-size: 1.2rem !important;
      transition: font-size 1s !important;
    }
    h1,.MuiTypography-h1{
      font-size: 1.25rem !important;
      transition: font-size 1s !important;
    }
    button{
      padding-top:2px !important;
      padding-bottom:2px !important;
    }
}
${theme.breakpoints.up('xl')} {
  font-size: 1.1rem !important;
  transition: font-size 1s !important;
  h5,.MuiTypography-h5{
    font-size: 1.15rem !important;
    transition: font-size 1s !important;
  }
  h4,.MuiTypography-h4{
    font-size: 1.2rem !important;
    transition: font-size 1s !important;
  }
  h3,.MuiTypography-h3{
    font-size: 1.25rem !important;
    transition: font-size 1s !important;
  }
  h2,.MuiTypography-h2{
    font-size: 1.3rem !important;
    transition: font-size 1s !important;
  }
  h1,.MuiTypography-h1{
    font-size: 1.35rem !important;
    transition: font-size 1s !important;
  }
  button{
    padding-top:2px !important;
    padding-bottom:2px !important;
  }
}

.MuiDataGrid-panelWrapper{
  background-color:${theme.palette.primary.light}; 
    color:${theme.palette.common.white}; 

    .MuiFormControl-root{
      background-color:#f5f5f5;
    }

    .MuiButton-textPrimary{
      background-color:${theme.palette.common.white}; 
      color:${theme.palette.primary.light}; 
    }
}
.MuiList-root{
    background-color:${theme.palette.primary.light}; 
    color:${theme.palette.common.white}; 
    .MuiTypography-root{
      color:${theme.palette.common.white}; 
    }
  }
  .MuiPaper-root{
    background:transparent;

    
  }

  .MuiTab-root{
    color:${theme.palette.primary.light};
    font-weight: bold;
  }
  }
  button.primary {
    background-color: ${theme.palette.common.white};
    border: 1px solid ${theme.palette.primary.light};
    color: ${theme.palette.primary.light};
    margin-right: 2px;

    &:hover{
      opacity:0.7;
      cursor: pointer;
    }

    &:disabled{
      background-color: ${theme.palette.common.white} !important;
      border: 1px solid grey !important;
      color: grey !important;
    }
    
    
}
button.secondary {
    background-color: ${theme.palette.common.white};
    border: 1px solid ${theme.palette.secondary.light} !important;
    color: ${theme.palette.secondary.light};
    margin-left:2px;
    
    &:hover{
      opacity:0.7;
      cursor: pointer;
    }
    
    &:disabled{
      background-color: lightgrey !important;
    }
}
.MuiSvgIcon-root{

  &:hover{
    opacity:0.7;
    cursor: pointer;
  }
}

.MuiSwitch-track{
  background-color: ${theme.palette.secondary.light} !important;
}

.MuiInputBase-input{
  -webkit-box-shadow: 0 0 0 100px ${theme.palette.common.white} inset !important;
  -webkit-text-fill-color: #000 !important;
  caret-color: #000 !important;
}

div#ActionFooter {
  display: flex;
  justify-content: flex-end;
  padding:10px;

}

.MuiInputBase-root{
  
  border-radius: 0px !important;
}


#popupModal {
  display: flex;
  align-items: center;
  justify-content: center;  

  .MuiBox-root{
   background-color: ${theme.palette.common.white};
   border: 1px solid ${theme.palette.primary.light};
   #modalTitle {
       background-color: ${theme.palette.primary.light}; 
       border-bottom: 1px solid ${theme.palette.common.black};
       color: ${theme.palette.common.white};
       min-height:2rem;
   }
               
   #modalDescription {
       background-color: ${theme.palette.common.white};
       color: 1px solid ${theme.palette.common.black};
       min-height:7rem;
       border-bottom: 1px solid ${theme.palette.primary.light};
       display: flex;
       align-items: center;
       justify-content: center;  


   }

   #modalFooter {
       display: flex;
       align-items: center;
       justify-content: center;
       padding-top: 8px;
       padding-bottom: 8px;
       background-color:${theme.palette.common.white};
   }  
   p{
    color: ${theme.palette.common.white};
   }
   
}
}

`;