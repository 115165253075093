import { InputLabel, TextField } from '@mui/material';


export interface ComparisonTextsProps {
    label?: string,
    currentValue?: string | null,
    comparisonValue?: string | null
}

export const ComparisonTextsData = (props: ComparisonTextsProps) => {


    return (
        <>
            <div className={'reportLabel1'}>
                <InputLabel>{props.label}</InputLabel>
            </div>
            <div className={'reportDataLabel1'}>
                <TextField fullWidth variant='outlined' size='small' value={props.comparisonValue} />
            </div>
            <div className={'reportDataLabel2'}>
                <TextField fullWidth variant='outlined' size='small' value={props.currentValue} />
            </div>
        </>
    );
};



export function ComparisonTexts(props: ComparisonTextsProps) {


    return (<ComparisonTextsData label={props.label} comparisonValue={props.comparisonValue} currentValue={props.currentValue} ></ComparisonTextsData>);



}

export default ComparisonTexts;