import React, { Key, ReactElement, ReactEventHandler, useCallback, useEffect, useState } from 'react'
import { ApiCall } from '../../services/ApiCall';
import HttpMethod from '../../constants/httpMethod.enum';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnVisibilityModel, GridFilterModel, GridPaginationMeta, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { StyledDataTable } from './styles';
import { GifRounded } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../configs/auth/authConfig';

export interface CustomSortModel {
    fieldName: string,
    fieldValue: string
}

export interface DataTableOptions {
    fetchUrl: string,
    method: HttpMethod,
    storeDataInLocalStorage?: boolean,
    columns: Array<GridColDef>,
    pageSizeOptions: Array<number>,
    multipleRowSelection: boolean,
    filterOptions?: any,//GridFilterModel,
    sortOptions?: GridSortModel,
    authRequest?: any,
    columnVisibilityModel?: GridColumnVisibilityModel
}


function DataTable(options: DataTableOptions) {
    const apiRef = useGridApiRef();
    const authRequest = options.authRequest ?? loginRequest;
    const [tableData, setTableData] = useState([]);

    const [filterModel, setFilterModel] = React.useState(options.filterOptions ?? null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>(options.sortOptions ?? []);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });
    const [rowsCount, setRowsCount] = React.useState<number>(0);
    const [currentPage, setCurrentPage] = React.useState<number>(0);

    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }



    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCount` from being undefined during the loading
    const rowCountRef = React.useRef(rowsCount || 0);

    const rowCount = React.useMemo(() => {
        if (rowsCount !== undefined) {
            rowCountRef.current = rowsCount;
        }
        return rowCountRef.current;
    }, [rowsCount]);

    const paginationMetaRef = React.useRef<GridPaginationMeta>();

    // Memoize to avoid flickering when the `hasNextPage` is `undefined` during refetch
 
    const paginationMeta = React.useMemo(() => {
        if (
            paginationMetaRef.current &&
            currentPage < (rowsCount / paginationModel.pageSize)
        ) {
            paginationMetaRef.current.hasNextPage = true;
        }
        return paginationMetaRef.current;
    }, [currentPage, rowsCount, paginationModel]);

    const internalFilterOptionsChange = (filterOptions: GridFilterModel) => {
        if (filterOptions) {
            setFilterModel(filterOptions);

        }
    }

    //Implements callback function that parent can subscribe to
    useEffect(() => internalFilterOptionsChange(options.filterOptions), [options.filterOptions]);

    const fetchData = () => {
        const dataFetchUrl = options.fetchUrl //+ '?' + (paginationModel.page > 0 ? 'PageNumber=' + (paginationModel.page + 1) : '') + (paginationModel.pageSize > 0 ? '&PageSize=' + paginationModel.pageSize : '')}`;

        const sortCustomModel: Array<CustomSortModel> = sortModel.map(x => ({ fieldName: x.field, fieldValue: (x.sort ? x.sort.toString() : 'asc') }));

        const data = {
            "pageNumber": (paginationModel.page > 0 ? paginationModel.page + 1 : 1),
            "pageSize": (paginationModel.pageSize > 0 ? paginationModel.pageSize : 10),
            "filters": filterModel ?? null,
            "sort": sortCustomModel ?? [],
        }
        instance.acquireTokenSilent({
            ...authRequest,
            account,
        }).then((response) => {
            if (response.accessToken) {
                ApiCall(dataFetchUrl, options.method, null, data, response.accessToken)
                    .then((response) => {
                        if (response && response.paginationHeader) {
                            setRowsCount(response?.paginationHeader?.rowsCount ?? 0);
                            setCurrentPage(response?.paginationHeader?.currentPage - 1 ?? 0);

                        }
                        if (response && response.content) {
                            if (options.storeDataInLocalStorage && options.storeDataInLocalStorage === true) {
                                localStorage.setItem('tableData', JSON.stringify(response.content));
                            }
                            setTableData(response.content);

                        }


                    })
                    .catch((error) => { console.log(error); })
            }
            else {
                instance.loginPopup(authRequest);
            }
        }).catch((error) => { console.log(error); });



    }

    useEffect(() => {
        fetchData();
    }, [paginationModel, sortModel, filterModel]);

    //console.log(tableData)
    return (
        <Box>
            <StyledDataTable>
                <DataGrid
                    apiRef={apiRef}
                    getRowId={(row) => crypto.randomUUID()}
                    rows={tableData}
                    rowCount={rowCount}
                    columns={options.columns}
                    pageSizeOptions={options.pageSizeOptions}
                    paginationMeta={paginationMeta}
                    paginationModel={paginationModel}
                    disableMultipleRowSelection={!options.multipleRowSelection}
                    paginationMode={'server'}
                    sortingMode={'server'}
                    disableAutosize={false}
                    autoHeight={false}
                    onPaginationModelChange={setPaginationModel}
                    onSortModelChange={setSortModel}
                    disableColumnFilter
                    columnVisibilityModel={options.columnVisibilityModel}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    sx={{

                    }}
                />
            </StyledDataTable>
        </Box>
    )
}

export default DataTable
function useQuery(paginationModel: { pageSize: number; page: number; }): { pageInfo: { hasNextPage: any; }; } {
    throw new Error('Function not implemented.');
}

