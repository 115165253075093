import { ReactNode, useState } from 'react';
import { FormHelperTextProps, InputLabel, TextField } from '@mui/material';


export interface MultipleLinesTextProps {
    label: string,
    value: number | string | null,
    minRows?: string | number | undefined,
}

export const MultipleLinesTextData = (props: MultipleLinesTextProps) => {
    const [value] = useState<number | string | null>(props.value);


    return (
        <>
            <div className={'reportLabel'}>
                <InputLabel>{props.label}</InputLabel>
            </div>
            <div className={'reportDataLabel'}>
                <TextField fullWidth multiline variant='outlined' size='small' value={value} minRows={props.minRows}  />
            </div>
        </>
    );
};



export function MultipleLinesText(props: MultipleLinesTextProps) {


    return (<MultipleLinesTextData label={props.label} value={props.value} minRows={props.minRows}  ></MultipleLinesTextData>);



}

export default MultipleLinesText;