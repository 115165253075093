import { useEffect, useState } from "react";


const Delay = (dependencyProperty: boolean, dependencyFunction: Function, autoCloseInterval: number) => {
    const [autoClose, setAutoClose] = useState<number>(-1);

    const funcAutoClose = () => {
        if (dependencyProperty) {
            setAutoClose(window.setTimeout(() => {
                if (dependencyProperty) {
                    dependencyFunction();
                }
            }, autoCloseInterval));

        }
    }


    useEffect(() => {
        if (autoClose && autoClose > 0) {
            clearTimeout(autoClose);
            setAutoClose(-1);
        }
        funcAutoClose();

    }, [dependencyProperty]);
}


export const useDelay = (dependencyProperty: boolean, dependencyFunction: Function, autoCloseInterval: number) => {
    return Delay(dependencyProperty, dependencyFunction, autoCloseInterval);
}