import { useEffect, useState } from 'react';

// Msal imports
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { apiConfig, loginAPIRequest } from '../configs/auth/authConfig';
// Sample app imports
import { ErrorComponent } from '../features/ErrorComponent/ErrorComponent';
import { Loading } from '../features/Loading/Loading';
import { Collapse, Grid, IconButton, InputLabel, Slide, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ApiCall } from '../services/ApiCall';
import HttpMethod from '../constants/httpMethod.enum';
import { GroupContainer, ScrollableDiv, StyledContainer, StyledContainer1 } from './styles/commonDetailPageStyles';
import { IContractorReportHistoryItem } from '../model/contractorReportHistory';
import StatusAction from '../features/StatusAction/StatusAction';
import FileAttachment from '../features/FileAttachment/FileAttachment';
import { deepClone } from '@mui/x-data-grid/internals';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SingleLineText from '../features/SingeLineTextElement/SingleLineText';
import ComparisonLabels from '../features/ComparisonLabel/ComparisonLabels';
import ComparisonTexts from '../features/ComparisonText/ComparisonTexts';
import AdditonalComments from '../features/AdditonalComments/AdditonalComments';
import MultipleLinesText from '../features/MultipleLinesTextElement/MultipleLinesText';
import SwitchElement from '../features/SwitchElement/SwitchElement';
import { IContract } from '../model/contract';
import { darkTheme as theme } from '../configs/theme';

export interface ContractHistoryProps {
  id: string
}

function ContractorHistoryReportContent(props: ContractHistoryProps) {
  const [tabValue, setTabValue] = useState('reportDetails');
  const [data, setData] = useState<IContractorReportHistoryItem | null>();
  const [contractor, setContractor] = useState<IContract | null>();

  const [open, setOpen] = useState<boolean[]>([true, true, true, true, true, true]);
  const dataFetchUrl = apiConfig.apiEndPoint + '/HseContractorReports/History/' + props.id;
  const contractorFetchUrl = apiConfig.apiEndPoint + '/Contracts/';

  const dateFormatter = new Intl.DateTimeFormat("en-US");
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }
  const collapsible = (arrayIndex: number) => {
    if (arrayIndex >= 0 && arrayIndex <= 4) {
      return (
        <div>
          <IconButton
            onClick={() => {
              const newOpen = deepClone(open);
              newOpen[arrayIndex] = !open[arrayIndex];
              setOpen(newOpen);
            }}
            aria-label="expand"
            size="large"
          >
            {open[arrayIndex] ? <ArrowDropUpIcon />
              : <ArrowDropDownIcon />}
          </IconButton>
        </div >
      )
    }
  };

  const fetchData = () => {
    const tableData = JSON.parse(localStorage.getItem('tableData') ?? "[]");
    if (tableData && tableData.length > 0) {
      const contractorReportHistoryItems: Array<IContractorReportHistoryItem> = tableData as Array<IContractorReportHistoryItem>;
      const filteredItems = contractorReportHistoryItems.filter((item) => { return item.hseContractorReportHistoryId === props.id });
      if (filteredItems.length > 0) {
        setData(filteredItems[0] as IContractorReportHistoryItem);
        const contractId = (filteredItems[0] as IContractorReportHistoryItem)?.contractNumber
        if (contractId !== null && contractId !== '') {
          instance.acquireTokenSilent({
            ...loginAPIRequest,
            account,
          }).then((response: any) => {
            if (response.accessToken) {
              const accessToken = response.accessToken;
              ApiCall(contractorFetchUrl + contractId, HttpMethod.GET, null, null, accessToken)
                .then((response) => {
                  if (response && response.content && response.content.status !== 404) {
                    setContractor(response.content ? response.content as IContract : null);
                    //navigate(window.location.pathname,{state:{tabValue},replace:true});
                  }


                })
                .catch((error) => { console.log(error); });
            }
            else {
              instance.loginPopup(loginAPIRequest);
            }
          })
        }
      }
      else {
        instance.acquireTokenSilent({
          ...loginAPIRequest,
          account,
        }).then((response: any) => {
          if (response.accessToken) {
            const accessToken = response.accessToken;
            ApiCall(dataFetchUrl, HttpMethod.POST, null, data, accessToken)
              .then((response) => {
                if (response && response.content) {
                  const contractorReportHistoryItems1: Array<IContractorReportHistoryItem> = response.content as Array<IContractorReportHistoryItem>;
                  const filteredItems1 = contractorReportHistoryItems1.filter((item) => { return item.hseContractorReportHistoryId === props.id });
                  if (filteredItems1.length > 0) {
                    setData(filteredItems1[0] as IContractorReportHistoryItem);
                    const contractId = (filteredItems[0] as IContractorReportHistoryItem)?.contractNumber
                    if (contractId !== null && contractId !== '') {

                      ApiCall(contractorFetchUrl + contractId, HttpMethod.GET, null, null, accessToken)
                        .then((response) => {
                          if (response && response.content && response.content.status !== 404) {
                            setContractor(response.content ? response.content as IContract : null);
                          }


                        })
                        .catch((error) => { console.log(error); });


                    }
                  }
                }


              })
              .catch((error) => { console.log(error); })
          }
          else {
            instance.loginPopup(loginAPIRequest);
          }
        })
          .catch((error: any) => { console.log(error); });
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Slide direction='up' in={true} timeout={500}>
        <ScrollableDiv>
          <StyledContainer>
            <div className={'sectionHeader'}>
              <InputLabel>Submission Details</InputLabel>
              {data &&
                <FileAttachment fileName={data.fileUrl.substring(data.fileUrl.lastIndexOf('/') + 1, data.fileUrl.length)} filePath={data.fileUrl} />
              }

            </div>

            {data &&
              <Grid container marginLeft={0} marginTop={0}>
                <Grid item sm={6} md={4}>
                  <SingleLineText label='Report Date:' value={data.reportDate} />
                </Grid>
                <Grid item sm={6} md={4}>
                  <SingleLineText label='Company:' value={data.company} />
                </Grid>
                <Grid item sm={6} md={4}>
                  <SingleLineText label='Submitter Email:' value={data.submitterEmail} />
                </Grid>

              </Grid>
            }
          </StyledContainer>
          <div>&nbsp;</div>
          <StyledContainer>
            <div className={'alternateSectionHeader'}>
              <InputLabel>SAP Data</InputLabel>
              {collapsible(0)}
            </div>

            {
              <Collapse in={open[0]} timeout="auto"
                unmountOnExit>
                {contractor !== null && contractor !== undefined ?
                  (<Grid container marginLeft={0} marginTop={0}>


                    <Grid item sm={6}>
                      <SingleLineText label='Contract Number:' value={contractor.contractId} />
                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='Contractor Owner:' value={contractor.contractOwner} />

                    </Grid>

                    <Grid item sm={6}>
                      <SingleLineText label='Vendor Number:' value={contractor.vendorNumber} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='Vendor:' value={contractor.vendorName} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='Department:' value={contractor.department} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='Scope:' value={contractor.scope} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='Start Date:' value={contractor?.startDate ? dateFormatter.format(Date.parse(contractor?.startDate.toString().substring(0, 4) + '-' + contractor?.startDate.toString().substring(4, 6) + '-' + contractor?.startDate.toString().substring(6, 8))) : ''} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='End Date:' value={contractor?.endDate ? dateFormatter.format(Date.parse(contractor?.endDate.toString().substring(0, 4) + '-' + contractor?.endDate.toString().substring(4, 6) + '-' + contractor?.endDate.toString().substring(6, 8))) : ''} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='HSE Manager Name:' value={contractor.hseManagerName} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='HSE Manager Email:' value={contractor.hseManagerEmail} />

                    </Grid>
                    <Grid item sm={6}>
                      <SingleLineText label='Contractor Owner Email:' value={contractor.contractOwnerEmail} />

                    </Grid>
                    <Grid item sm={6}>
                      <SwitchElement label='Is Active:' color={'primary'} checked={contractor.isValid === 'IsValid' || contractor.isValid.toUpperCase() === 'TRUE'} />

                    </Grid>


                  </Grid>) :
                  (<Typography style={{ color: theme.palette.error.main, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '52px' }}>No SAP Data found</Typography>)
                }
              </Collapse>
            }



          </StyledContainer>
          
          <div>&nbsp;</div>
          <StyledContainer>
            <div className={'sectionHeader'}>
              <InputLabel>Contract Details</InputLabel>
              {collapsible(1)}
            </div>

            {data &&
              <Collapse in={open[1]} timeout="auto"
                unmountOnExit>
                <Grid container marginLeft={0} marginTop={0}>

                  <Grid item sm={6}>
                    <SingleLineText label='Contractor Name:' value={data.contractorName} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Contractor Owner:' value={data.contractOwner} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Contract Number:' value={data.contractNumber} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Submission Date:' value={data.submittedDate && !isNaN(Date.parse(data.submittedDate)) ? dateFormatter.format(Date.parse(data.submittedDate)) : ''} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Scope of Contract:' value={data.contractScope} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Validity End Date:' value={data.validityEndDate && !isNaN(Date.parse(data.validityEndDate)) ? dateFormatter.format(Date.parse(data.validityEndDate)) : ''} />
                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='No. of Sub-Contractors (if any):' value={data.totalSubContractors} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='No. of Sub-Contractors Level (if any):' value={data.totalSubContractorLevel} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='No. of Contractor POB (onshore & offshore):' value={data.totalContractorPOB} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='No. of Sub-Contractors POB (onshore & offshore):' value={data.totalSubContractorPOB} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Man-Hours of Contractor personnel engaged in execution of contract (onshore & offshore):' value={data.contactPersonalManHours} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Man-Hours of Sub-Contractors personnel engaged in execution of contract (onshore & offshore):' value={data.subContractPersonalManHours} />

                  </Grid>

                </Grid>
              </Collapse>
            }

          </StyledContainer>
          <div>&nbsp;</div>
          <StyledContainer>
            <div className={'sectionHeader'}>
              <InputLabel>Incident Free (IF) Program Details</InputLabel>
              {collapsible(2)}
            </div>

            {data &&
              <Collapse in={open[2]} timeout="auto"
                unmountOnExit>
                <Grid container marginLeft={0} marginTop={0}>
                  <Grid item sm={6}>
                    <SingleLineText label='HSE Manager Name:' value={data.hseManagerName} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='HSE Manager Email:' value={data.hseManagerEmail} type={'email'} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Nominated/ Trained IF program trainers names:' value={data.nominatedIFProgramTrainerName} />


                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='Nominated/ Trained IF program trainers contacts:' value={data.nominatedIFProgramTrainerContactDetails} />

                  </Grid>

                  <Grid item sm={6}>
                    <SingleLineText label='No. of nominated / trained IF champions:' value={data.numberOfNominatedIFChampions} />


                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='No. of HSE golden rules e-Learning completion should be (&gt;= Max T POB):' value={data.numberOfGoldenRuleELearningCompletion} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='% compliance to IF induction:' value={data.percentageOfComplianceToIFInduction} />

                  </Grid>
                  <Grid item sm={6}>
                    <SingleLineText label='No. of HSE recognition given to contractor personnel per month:' value={data.numberOfHSERecognitionGivenPerMonth} />

                  </Grid>


                </Grid>
              </Collapse>
            }

          </StyledContainer>

          <div>&nbsp;</div>
          <GroupContainer>
            <StyledContainer1>
              <div className={'sectionHeader1'}>
                <InputLabel>Leading Practices Implemented</InputLabel>
              </div>

              {data &&
                <Collapse in={open[3]} timeout="auto"
                  unmountOnExit>
                  <Grid container marginLeft={0} marginTop={0} >
                    <Grid item >
                      <ComparisonLabels currentLabel='YTD' comparisonLabel='Last Month' />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Compliance to HSE golden rules e-Learning (Total number of golden rules e-learning certificates issued/ total manpower required by NOC*100):' comparisonValue={data.percentageOfComplianceToHSEGolderRulesLastMonth} currentValue={data.percentageOfComplianceToHSEGolderRulesYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='No. of observation cards submitted:' comparisonValue={data.observationCardsSubmittedLastMonth} currentValue={data.observationCardsSubmittedYTD} />


                    </Grid>
                    <Grid item>
                      <ComparisonTexts label='No. of emergency drills:' comparisonValue={data.emergencyDrillsLastMonth} currentValue={data.emergencyDrillsYTD} />

                    </Grid>
                    <Grid item>
                      <ComparisonTexts label='No. of HSE tours (including management tours):' comparisonValue={data.hseToursLastMonth} currentValue={data.hseToursYTD} />
                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='No. of HSE stand downs / talks:' comparisonValue={data.hseStandDownsLastMonth} currentValue={data.hseStandDownsYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Percentage compliance to company HSE training matrix:' comparisonValue={data.percentageComplianceToCompanyHSETrainingMatrixLastMonth} currentValue={data.percentageComplianceToCompanyHSETrainingMatrixYTD} />


                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Percentage compliance to contractor HSE training matrix:' comparisonValue={data.percentageComplianceToContractorHSETrainingMatrixLastMonth} currentValue={data.percentageComplianceToContractorHSETrainingMatrixYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='% Compliance to HSE audit plans:' comparisonValue={data.percentageComplianceToHSEAuditPlanLastMonth} currentValue={data.percentageComplianceToHSEAuditPlanYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='IF Inductions conducted by contractor:' comparisonValue={data.ifInductionsConductedByContractorLastMonth} currentValue={data.ifInductionsConductedByContractorYTD} />

                    </Grid>
                  </Grid>
                </Collapse>
              }

            </StyledContainer1>
            <StyledContainer1>
              <div className={'sectionHeader1'}>
                <InputLabel>Details Of</InputLabel>
                {collapsible(3)}
              </div>

              {data &&
                <Collapse in={open[3]} timeout="auto"
                  unmountOnExit>
                  <Grid container marginLeft={0} marginTop={0}>
                    <Grid item >
                      <ComparisonLabels currentLabel='YTD' comparisonLabel='Last Month' />
                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='MTC:' comparisonValue={data.mtcLastMonth} currentValue={data.mtcytd} />

                    </Grid>

                    <Grid item >
                      <ComparisonTexts label='LTI:' comparisonValue={data.ltiLastMonth} currentValue={data.ltiytd} />

                    </Grid>

                    <Grid item >
                      <ComparisonTexts label='First Aid:' comparisonValue={data.firstAidLastMonth} currentValue={data.firstAidYTD} />

                    </Grid>

                    <Grid item >
                      <ComparisonTexts label='RWDC:' comparisonValue={data.rwdcLastMonth} currentValue={data.rwdcytd} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Fatality:' comparisonValue={data.fatalityLastMonth} currentValue={data.fatalityYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='High Potential (HIPOs):' comparisonValue={data.highPotentialLastMonth} currentValue={data.highPotentialYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Heat Stress Cases:' comparisonValue={data.heatStressCasesLastMonth} currentValue={data.heatStressCasesYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='No. of recordable and HIPO incidents involving sub-contractor:' comparisonValue={data.recordableHIPOIncidentsInvolvingSubContractorLastMonth} currentValue={data.recordableHIPOIncidentsInvolvingSubContractorYTD} />


                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Near Miss:' comparisonValue={data.nearMissLastMonth} currentValue={data.nearMissYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='No. of vehicle incidents (LV&HV):' comparisonValue={data.vechicleIncidentsLastMonth} currentValue={data.vechicleIncidentsYTD} />

                    </Grid>

                  </Grid>
                </Collapse>
              }

            </StyledContainer1>
          </GroupContainer>
          <div>&nbsp;</div>
          <GroupContainer>
            <StyledContainer1>
              <div className={'sectionHeader1'}>
                <InputLabel>Environment</InputLabel>
              </div>

              {data &&
                <Collapse in={open[4]} timeout="auto"
                  unmountOnExit>
                  <Grid container marginLeft={0} marginTop={0} >
                    <Grid item >
                      <ComparisonLabels currentLabel='YTD' comparisonLabel='Last Month' />
                    </Grid>

                    <Grid item >
                      <ComparisonTexts label='Liquid fuel consumptions (Tons):' comparisonValue={data.liquidFuelConsumptionLastMonth} currentValue={data.liquidFuelConsumptionYTD} />


                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Freshwater consumption (m3):' comparisonValue={data.freshWaterConsumptionLastMonth} currentValue={data.freshWaterConsumptionYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Wastewater discharges to sea and its oil in water concentration (m3):' comparisonValue={data.wasteDischargeToSeaAndOilInWaterConcentrationLastMonth} currentValue={data.wasteDischargeToSeaAndOilInWaterConcentrationYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Discharges of cuttings/mud to sea and its oil on cutting concentration (Tons):' comparisonValue={data.dischargeOfCuttingsToSeaAndOilOnCuttingConcentrationLastMonth} currentValue={data.dischargeOfCuttingsToSeaAndOilOnCuttingConcentrationYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Quantity of waste generation (hazardous, non-hazardous, and inert wastes) and their disposal methods (Tons):' comparisonValue={data.qualityOfWasteGenerationAndDisposalMethodsLastMonth} currentValue={data.qualityOfWasteGenerationAndDisposalMethodsYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Chemical consumption:' comparisonValue={data.chemicalConsumptionLastMonth} currentValue={data.chemicalConsumptionYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Air quality and emissions of pollutants to the atmosphere and dust control:' comparisonValue={data.airQualityAndEmissionsLastMonth} currentValue={data.airQualityAndEmissionsYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Energy consumption:' comparisonValue={data.energyConsumptionLastMonth} currentValue={data.energyConsumptionYTD} />

                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='Environmental Incidents including but not limited to spills greater than one (1) barrel of oil or chemical product:' comparisonValue={data.environmentalIncidentsLastMonth} currentValue={data.environmentalIncidentsYTD} />

                    </Grid>
                  </Grid>
                </Collapse>
              }

            </StyledContainer1>
            <StyledContainer1>
              <div className={'sectionHeader1'}>
                <InputLabel>HSE Plan Related to Performance Measure</InputLabel>
                {collapsible(4)}
              </div>

              {data &&
                <Collapse in={open[4]} timeout="auto"
                  unmountOnExit>
                  <Grid container marginLeft={0} marginTop={0} >
                    <Grid item >
                      <ComparisonLabels currentLabel='YTD' comparisonLabel='Last Month' />
                    </Grid>
                    <Grid item >
                      <ComparisonTexts label='COVID- 19 Vaccination status:' comparisonValue={data.covid19VaccinationStatusLastMonth} currentValue={data.covid19VaccinationStatusYTD} />

                    </Grid>

                    <Grid item >
                      <AdditonalComments label='Additional Comments:' value={data.additionalComment} />

                    </Grid>


                  </Grid>
                </Collapse>
              }

            </StyledContainer1>
          </GroupContainer>
          <div>&nbsp;</div>
          <StyledContainer>
            <div className={'sectionHeader'}>
              <InputLabel>Incident Details - Description Only If Applicable</InputLabel>
              {collapsible(5)}
            </div>

            {data &&
              <Collapse in={open[5]} timeout="auto"
                unmountOnExit>
                <Grid container marginLeft={0} marginTop={0}>

                  <Grid item sm={6}>
                    <MultipleLinesText label='MTC:' value={data.mtc} />

                  </Grid>
                  <Grid item sm={6}>
                    <MultipleLinesText label='LTI:' value={data.lti} />

                  </Grid>
                  <Grid item sm={6}>
                    <MultipleLinesText label='First Aid:' value={data.firstAid} />

                  </Grid>
                  <Grid item sm={6}>
                    <MultipleLinesText label='RWDC:' value={data.rwdc} />

                  </Grid>

                  <Grid item sm={6}>
                    <MultipleLinesText label='Fatality:' value={data.fatality} />


                  </Grid>
                  <Grid item sm={6}>
                    <MultipleLinesText label='High Potential (HIPOs):' value={data.highPotential} />

                  </Grid>
                  <Grid item sm={6}>
                    <MultipleLinesText label='Heat Stress cases:' value={data.heatStressCases} />

                  </Grid>
                  <Grid item sm={6}>
                    <MultipleLinesText label='No. of recordable and HIPO incidents involving sub-contractor:' value={data.recordableHIPOIncidentsInvolvingSubContractor} />

                  </Grid>
                  <Grid item sm={6}>

                    <MultipleLinesText label='Near Miss:' value={data.nearMiss} />

                  </Grid>
                  <Grid item sm={6}>
                    <MultipleLinesText label='No. of vehicle incidents (LV & HV):' value={data.vehicleIncidents} />

                  </Grid>

                </Grid>
              </Collapse>
            }

          </StyledContainer>
          <div>&nbsp;</div>

          <StyledContainer>
            {data &&
              <StatusAction reportId={props.id} scaningStatus={data.scaningStatus} status={data.status} comment={data.reviewerComment} readOnly={true} />
            }

          </StyledContainer>
        </ScrollableDiv>
      </Slide >
    </>
  );


}

export function ContractorHistoryReport() {
  const routeParams = useParams();
  const authRequest = {
    ...loginAPIRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      {routeParams.id && <ContractorHistoryReportContent id={routeParams.id ?? ''} />}
    </MsalAuthenticationTemplate>
  );
}