
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useMemo, useState } from 'react';
import { darkTheme as theme } from '../../configs/theme';
import Routes from '../../constants/routes.enum';
import SignInSignOutButton from '../SignInSignOutButton/SignInSignOutButton';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import WelcomeName from '../WelcomeName/WelcomeName';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import RouteConstants from '../../constants/routes.enum';

const links: Array<[string, string]> = [
  [Routes.HOME, 'Home'],
  [Routes.PROFILE, 'Profile'],
];

function NavBar() {
  const isAuthenticated = useIsAuthenticated();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const rootPaths = [RouteConstants.HOME, RouteConstants.HOME + RouteConstants.VENDORS, RouteConstants.HOME + RouteConstants.CONTRACTORHISTORYREPORT];

  const isMatch = rootPaths.some((path) =>
    matchPath(path, pathname)
  );
  



  return (
    <div
      style={{ display: 'flex', minWidth: '100%', maxHeight: '48px', backgroundColor: theme.palette.common.white, flexGrow: 1, paddingLeft: `${isAuthenticated ? 60 : 0}px` }}
    >
      <AppBar style={{ backgroundColor: 'transparent', display: 'flex', width: '100%', maxHeight: '48px', marginBottom:'5px'}} position="static">
        <Toolbar variant="dense" disableGutters sx={{}}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'left', minWidth: '30%', paddingLeft: '5px', width: '30%' }}>
            {!isMatch &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', paddingBottom: '4px' }}>
                <Button id='Back' className='primary' onClick={() => { navigate(-1); }} ><ArrowBackIosNewIcon />Back</Button>
              </div>
            }

          </Box>
          {/*  <Box sx={{ display: { md: 'block', xs: 'none' }, minWidth: '50%', width: '50%' }}>
            {isAuthenticated && links.map(([to, label]) => (
              <Button key={label} sx={{ color: '#fff' }}>
                <Link to={to} style={{ textDecoration: 'none', color:'#fff' }}>
                  {label}
                </Link>
              </Button>
            ))}
          </Box> */}

          <Box sx={{ display: { xs: 'flex' }, justifyContent: 'right', width: '100%' }}>
            <Box sx={{ display: { md: 'flex', xs: 'none', minWidth: '50%', width: '50%' }, justifyContent: 'center',alignItems:'center', fontWeight:'bold' }}>
              <Typography
                variant="h1"
                noWrap
                component="a"
                href={Routes.HOME}
                sx={{
                  color: theme.palette.primary.light,
                  display: 'contents',
                  flexGrow: 1,
                  mr: 2,
                  pl: 2,
                  textDecoration: 'none',
                  '&:hover': {
                    zoom: '1.0',
                  }
                }}
                className=''
              >
                Contractor Reporting
              </Typography>
              {/*   <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                keepMounted
                transformOrigin={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { md: 'none', xs: 'block' },
                }}
              >
                {isAuthenticated && links.map(([to, label]) => (
                  <MenuItem key={label} onClick={handleCloseNavMenu} sx={{ color: 'white', display: 'block', textAlign: 'right' }}>
                     <Link to={to} style={{ textDecoration: 'none', color:'#fff' }}>
                  {label}
                </Link>
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton> */}
            </Box>
            <div style={{ display: 'flex', justifyContent: 'right', marginRight: '10px', width: '100%' }}>
              <WelcomeName />
              <SignInSignOutButton />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NavBar;
