import styled from 'styled-components';
import { darkTheme as theme } from '../../configs/theme'


export const StyledValidationContainer = styled.div`
border: 1px solid red;
padding-left:8px;
padding-right:8px;
overflow-y: auto;
color: red;
background-color:#f4a7a7;
display: flex;
justify-content:start;
align-items:center;
min-height: 48px;


`;


export const StyledContainer = styled.div`
border: 1px solid #e0e0e0;//${theme.palette.primary.light};
.sectionHeader{
    padding: 5px;
    background-color:${theme.palette.primary.light};
    display: flex;
    min-height: 58px;

    .MuiFormLabel-root,.MuiInputLabel-root{
        font-weight: bold;
        width: 50%;
        align-self: center;
        color:${theme.palette.common.white};
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 8px;
        width: 100%;
        button svg {
            color:${theme.palette.common.white};
        }
    }
}

.alternateSectionHeader{
    padding: 5px;
    background-color:${theme.palette.secondary.light};
    display: flex;
    min-height: 58px;

    .MuiFormLabel-root,.MuiInputLabel-root{
        font-weight: bold;
        width: 50%;
        align-self: center;
        color:${theme.palette.common.white};
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 8px;
        width: 100%;
        button {
            &:hover{
                background-color:${theme.palette.secondary.light} !important;
            }
            svg {
                color:${theme.palette.common.white};
            }
        }
    }
}

.MuiGrid-root, .MuiGrid-root > .MuiGrid-root{
    color: ${theme.palette.common.black};
    width: -webkit-fill-available;
    margin-left:0;
    margin-top:0;
    padding-left:0;
    margin-top:0;
    .MuiGrid-item{
        display:flex;
    }
    .reportLabel{
        padding:5px;
        min-width:33%;
        display:flex;
        align-items: center;
        label{
            color: ${theme.palette.common.black};
            text-wrap: wrap;
        }
    }
    .reportDataLabel{
        padding:5px;
        min-width:67%;
        display:flex;
        align-items: center;
        .MuiInputBase-root{
            background-color:${theme.palette.common.white}; 
            color: grey;
            //border: 1px solid #f5f5f5;
        }
    }

    .reportLabel4{
        padding:5px;
        min-width:25%;
        display:flex;
        align-items: center;
        label{
            color: ${theme.palette.common.black};
            text-wrap: wrap;
        }
    }
    .reportDataLabel4{
        padding:5px;
        min-width:75%;
        display:flex;
        align-items: center;
        .MuiInputBase-root{
            background-color:${theme.palette.common.white}; 
            color: grey;
            //border: 1px solid #f5f5f5;
        }
    }
    
}


`;
export const GroupContainer = styled.div`
border: none;
display: flex;
flex-wrap:wrap;
width: 100%;
    ${theme.breakpoints.up('md')} {
        border: 1px solid #e0e0e0;//${theme.palette.primary.light};
    }
}
`;
export const StyledContainer1 = styled.div`
border: 1px solid #e0e0e0;//${theme.palette.primary.light};
width: 100%;
display: flex;
min-height: 58px;
flex-direction: column;
    ${theme.breakpoints.up('md')} {
        border: none;
        width: 50%;
    }

.sectionHeader1{
    padding: 5px;
    background-color:${theme.palette.primary.light};
    display: flex;
    flex-direction: row;
    min-height: 58px;

    .MuiFormLabel-root,.MuiInputLabel-root{
        display: flex;
        align-self: center;
        font-weight: bold;
        width: 100%;
        color:${theme.palette.common.white};
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 8px;
        width: 100%;
        button svg {
            color:${theme.palette.common.white};
        }
    }
}

.MuiGrid-root, .MuiGrid-root > .MuiGrid-root{
    color: ${theme.palette.common.black};
    width: -webkit-fill-available;
    max-width: 100%;
    margin-left:0;
    margin-top:0;
    padding-left:0;
    margin-top:0;
    .MuiGrid-item{
        display:flex;
    }
    .reportLabel1{
        padding:5px;
        min-width:50%;
        max-width:50%;
        display:flex;
        align-items: center;
        justify-content: left;
        label{
            color: ${theme.palette.common.black};
            text-wrap: wrap;
        }
    }
    .reportDataLabel1{
        padding:5px;
        min-width:25%;
        max-width:25%;
        display:flex;
        align-items: center;
        justify-content: center;
        .MuiInputBase-root{
            background-color:${theme.palette.common.white}; 
            color: grey;
           // border: 1px solid #f5f5f5;
        }
    }

    .reportDataLabel2{
        padding:5px;
        min-width:25%;
        max-width:25%;
        display:flex;
        align-items: center;
        justify-content: center;
        .MuiInputBase-root{
            background-color:${theme.palette.common.white}; 
            color: grey;
            //border: 1px solid #f5f5f5;
        }
    }

    .reportDataLabel3{
        padding:5px;
        min-width:50%;
        max-width:50%;
        display:flex;
        align-items: center;
        .MuiInputBase-root{
            background-color:${theme.palette.common.white}; 
            color: grey;
            //border: 1px solid #f5f5f5;
        }
    }
}
`;
export const WrappedContractorDiv = styled.div`
padding-left:24px;
padding-right:24px;
.MuiDataGrid-virtualScrollerContent{
height: calc(100vh - 275px) !important;
padding-bottom:10px;

}
.MuiDataGrid-filler{
    display:none;
}
`;
export const ScrollableDiv = styled.div`
padding-left:24px;
padding-right:24px;
height: calc(100vh - 80px) !important;
padding-bottom:10px;
overflow-y:auto;
padding: 24px;

}
`;