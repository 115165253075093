import { useState } from 'react';
import { InputLabel, TextField } from '@mui/material';


export interface AdditonalCommentsProps {
    label: string,
    value: string | null,

}

export const AdditonalCommentshData = (props: AdditonalCommentsProps) => {
    const [value] = useState<number | string | null>(props.value);

    return (
        <>
            <div className={'reportLabel1'}>
                <InputLabel>{props.label}</InputLabel>
            </div>
            <div className={'reportDataLabel3'}>
                <TextField fullWidth multiline minRows={3} variant='outlined' size='small' value={value} />
            </div>
        </>
    );
};



export function AdditonalComments(props: AdditonalCommentsProps) {


    return (<AdditonalCommentshData label={props.label} value={props.value} ></AdditonalCommentshData>);



}

export default AdditonalComments;