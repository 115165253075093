import { ReactNode, useState } from 'react';
import { FormHelperTextProps, InputLabel, TextField } from '@mui/material';


export interface SingleLineTextProps {
    label: string,
    value: number | string | null,
    type?: React.HTMLInputTypeAttribute | undefined,
    error?: boolean | undefined,
    helperText?: ReactNode,
    FormHelperTextProps?: Partial<FormHelperTextProps>|undefined,
}

export const SingleLineTextData = (props: SingleLineTextProps) => {
    const [value] = useState<number | string | null>(props.value);


    return (
        <>
            <div className={'reportLabel'}>
                <InputLabel>{props.label}</InputLabel>
            </div>
            <div className={'reportDataLabel'}>
                <TextField fullWidth variant='outlined' size='small' value={value} type={props.type} error={props.error} helperText={props.helperText} FormHelperTextProps={props.FormHelperTextProps} />
            </div>
        </>
    );
};



export function SingleLineText(props: SingleLineTextProps) {


    return (<SingleLineTextData label={props.label} value={props.value} error={props.error} helperText={props.helperText} FormHelperTextProps={props.FormHelperTextProps} ></SingleLineTextData>);



}

export default SingleLineText;