import { useEffect, useState } from 'react';

// Msal imports
import type { AccountInfo } from '@azure/msal-browser';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import Paper from '@mui/material/Paper';
import { loginRequest } from '../configs/auth/authConfig';
import { darkTheme as theme } from '../configs/theme';
// Sample app imports
import { ErrorComponent } from '../features/ErrorComponent/ErrorComponent';
import { Loading } from '../features/Loading/Loading';
import type { GraphData } from '../features/ProfileData/ProfileData';
import { ProfileData } from '../features/ProfileData/ProfileData';
import { MsGraphApiCall } from '../services/MsGraphApiCall';
import { Typography } from '@mui/material';

// Material-ui imports

function ProfileContent() {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | GraphData>(null);
  const [accessToken, setAccessToken] = useState<null | string>(null);
  const account = instance.getActiveAccount();
  if (!account) {
    throw ('No active account! Verify a user has been signed in and setActiveAccount has been called.');

  }


  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent({
        ...loginRequest,
        account,
      }).then(resp => {
        setAccessToken(resp.accessToken);
        MsGraphApiCall(resp.accessToken)
          .then((response) => setGraphData(response))
          .catch((e) => {
            if (e instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenRedirect({
                  ...loginRequest,
                  account: instance.getActiveAccount() as AccountInfo,
                })
                .catch((error) => { console.error(error); });
            }
            else {
              console.log(e);
            }
          });
      }).catch((e) => {

        console.log(e);

      });

    }
  }, [inProgress, graphData, instance])

  return <Paper>{graphData ? <ProfileData graphData={graphData} /> : <Typography variant="h6" align="center" sx={{ color: theme.palette.primary.main }}>
    Please sign-in to see your profile information.
  </Typography>}
  </Paper>;


}

export function Profile() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ProfileContent />
    </MsalAuthenticationTemplate>
  );
}

function useAuthToken(): { accessToken: any; } {
  throw new Error('Function not implemented.');
}
