import styled from 'styled-components';
import { darkTheme as theme } from '../../configs/theme'

export const StyledForm = styled.form`
background-color: ${theme.palette.common.white};
margin-top: 20px;
margin-left:24px;
margin-right:24px;

border: 1px solid #e0e0e0;


.MuiGrid-root {
    background-color:${theme.palette.common.white}; 
    align-items: center;
    padding-bottom: 5px;

    > .MuiGrid-root {
    display:inline-flex;
    width: -webkit-fill-available;

    div.homeSearchLabel {
        padding:5px;
        min-width:33%;
        display:flex;
        align-items: center;

        .MuiInputLabel-root{
            /*font-weight:bold;
            padding:10px;*/
            color:${theme.palette.common.black};
            text-wrap:wrap; 
            
        }
        
        
    }

    div.homeSearchInput {
        padding:5px;
        min-width:67%;
        display:flex;
        align-items: center;
        .MuiInputBase-root{
            background-color:${theme.palette.common.white}; 
            color:${theme.palette.common.black}; 
        }
        
}


}

div#FormFooter {
    display: flex;
    justify-content: flex-end;
    padding:10px;

}
}
`;
export const WrappedDiv = styled.div`

padding-left:24px;
padding-right:24px;
.MuiDataGrid-virtualScrollerContent{
    height: calc(100vh - 685px) !important;

padding-bottom:10px;

${theme.breakpoints.up('xs')} {
    height: calc(100vh - 665px) !important;    
}
${theme.breakpoints.up('sm')} {
    height: calc(100vh - 645px) !important;    
}
${theme.breakpoints.up('md')} {
    height: calc(100vh - 525px) !important;    
}
${theme.breakpoints.up('lg')} {
    height: calc(100vh - 505px) !important;    
}
}
.MuiDataGrid-filler{
    display:none;
}
.MuiSwitch-root {
    .MuiSwitch-switchBase{
        .MuiSwitch-thumb{
            color:${theme.palette.primary.light};
        }
    }
}

`;

export const UnAuthenticatedDiv = styled.div`
height: calc(100vh - 80px) !important;
padding-bottom:10px;
overflow-y:auto;
padding: 24px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
`;