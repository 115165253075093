
import { useMsal } from '@azure/msal-react';
import { graphConfig, loginRequest } from '../configs/auth/authConfig';

export async function MsGraphApiCall(accessToken:string) {
  

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    headers,
    method: 'GET',
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}