import styled from 'styled-components';
import { darkTheme as theme } from '../configs/theme';

export const StyledMainContent = styled.div`
//font-size: 0.8rem;
${theme.breakpoints.up('sm')} {

    //font-size: 1.0rem;
}
${theme.breakpoints.up('md')} {
    //font-size: 1.3rem;
}
${theme.breakpoints.up('lg')} {
    //font-size: 1.5rem;
}

`;

export const StyledButtonContainer = styled.div`
display:flex;

button.MuiButton-root{
    &:hover {
        zoom:1.0;
        cursor: pointer;
    }
}
`;