import { useEffect, useState } from 'react';

// Msal imports
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { apiConfig, loginAPIRequest } from '../configs/auth/authConfig';
import { darkTheme as theme } from '../configs/theme';
// Sample app imports
import { ErrorComponent } from '../features/ErrorComponent/ErrorComponent';
import { Loading } from '../features/Loading/Loading';
import { Grid, InputLabel, Slide, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ApiCall } from '../services/ApiCall';
import HttpMethod from '../constants/httpMethod.enum';
import { TabPanel } from '../features/TabPanel/TabPanel';
import { StyledContainer } from './styles/commonDetailPageStyles';
import { WrappedDiv } from './styles/commonPageStyles';
import { IContract } from '../model/contract';
import SingleLineText from '../features/SingeLineTextElement/SingleLineText';
import SwitchElement from '../features/SwitchElement/SwitchElement';

export interface ContractProps {
  id: string
}

function VendorContent(props: ContractProps) {
  const [tabValue, setTabValue] = useState('contractDetails');
  const [data, setData] = useState<IContract | null>();
  const dataFetchUrl = apiConfig.apiEndPoint + '/Contracts/' + props.id;
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const dateFormatter = new Intl.DateTimeFormat('en-US');



  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const fetchData = () => {
    instance.acquireTokenSilent({
      ...loginAPIRequest,
      account,
    }).then((response: any) => {
      if (response.accessToken) {
        ApiCall(dataFetchUrl, HttpMethod.GET, null, null, response.accessToken)
          .then((response) => {
            if (response && response.content) {
              setData(response.content ? response.content as IContract : null);
            }


          })
          .catch((error) => { console.log(error); })
      }
      else {
        instance.loginPopup(loginAPIRequest);
      }
    }).catch((error: any) => { console.log(error); });
  }

  useEffect(() => {
    fetchData();
  }, [tabValue]);

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label='Contract Details'
        textColor='secondary'
        indicatorColor='secondary'
        sx={{ backgroundColor: theme.palette.common.white, padding: '24px' }}
      >
        <Tab value='contractDetails' label='Contractor Details' />

      </Tabs>
      <TabPanel value={tabValue} index={'contractDetails'}>

        <Slide direction='up' in={true} timeout={500}>
          <WrappedDiv>
            <StyledContainer>
              <div className={'sectionHeader'}>
                <InputLabel>Contract Details</InputLabel>
              </div>

              {data &&
                <Grid container marginLeft={0} marginTop={0}>

<Grid item sm={6}>
                        <SingleLineText label='Contract Number:' value={data.contractId} />
                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='Contractor Owner:' value={data.contractOwner} />

                      </Grid>

                      <Grid item sm={6}>
                        <SingleLineText label='Vendor Number:' value={data.vendorNumber} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='Vendor:' value={data.vendorName} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='Department:' value={data.department} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='Scope:' value={data.scope} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='Start Date:' value={data?.startDate ? dateFormatter.format(Date.parse(data?.startDate.toString().substring(0, 4) + '-' + data?.startDate.toString().substring(4, 6) + '-' + data?.startDate.toString().substring(6, 8))) : ''} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='End Date:' value={data?.endDate ? dateFormatter.format(Date.parse(data?.endDate.toString().substring(0, 4) + '-' + data?.endDate.toString().substring(4, 6) + '-' + data?.endDate.toString().substring(6, 8))) : ''} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='HSE Manager Name:' value={data.hseManagerName} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='HSE Manager Email:' value={data.hseManagerEmail} />

                      </Grid>
                      <Grid item sm={6}>
                        <SingleLineText label='Contractor Owner Email:' value={data.contractOwnerEmail} />

                      </Grid>
                      <Grid item sm={6}>
                        <SwitchElement label='Is Active:' color={'primary'} checked={data.isValid === 'IsValid' || data.isValid.toUpperCase() === 'TRUE'} />

                      </Grid>


                </Grid>
              }

            </StyledContainer>
            <div>&nbsp;</div>

          </WrappedDiv>
        </Slide>


      </TabPanel>

    </>
  );


}

export function VendorDetail() {
  const routeParams = useParams();
  const authRequest = {
    ...loginAPIRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      {routeParams.id && <VendorContent id={routeParams.id ?? ''} />}
    </MsalAuthenticationTemplate>
  );
}