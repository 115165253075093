import { useEffect, useState } from 'react';

// Msal imports
import type { AccountInfo } from '@azure/msal-browser';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import Paper from '@mui/material/Paper';
import { loginRequest } from '../configs/auth/authConfig';
// Sample app imports
import { ErrorComponent } from '../features/ErrorComponent/ErrorComponent';
import { Loading } from '../features/Loading/Loading';
import type { GraphData } from '../features/UserData/UserData';
import { MsGraphApiCall } from '../services/MsGraphApiCall';
import { UserData } from '../features/UserData/UserData';

// Material-ui imports

function UserContent() {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | GraphData>(null);
  const [accessToken, setAccessToken] = useState<null | string>(null);
  const account = instance.getActiveAccount();
  if (!account) {
    throw ('No active account! Verify a user has been signed in and setActiveAccount has been called.');

  }


  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setGraphData(JSON.parse(userData) as GraphData);
    }
    else {
      if (!graphData && inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent({
          ...loginRequest,
          account,
        }).then(resp => {
          setAccessToken(resp.accessToken);
          MsGraphApiCall(resp.accessToken)
            .then((response) => {
              if (response) {
                localStorage.setItem('userData', JSON.stringify(response));
              }
              setGraphData(response);

            })
            .catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                instance
                  .acquireTokenRedirect({
                    ...loginRequest,
                    account: instance.getActiveAccount() as AccountInfo,
                  })
                  .catch((error) => { console.error(error); });
              }
              else {
                console.error(e);
              }
            });
        }).catch((e) => {

          console.error(e);

        });

      }
    }
  }, [inProgress, instance])

  const returnedData = graphData ? <Paper sx={{ backgroundColor: 'transparent', }}><UserData graphData={graphData} /></Paper> : <Paper sx={{ backgroundColor: 'transparent', }}><div style={{ minWidth: '210px' }}></div></Paper>;

  return returnedData;





}

export function User() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <UserContent />
    </MsalAuthenticationTemplate>
  );
}
