import { useAccount, useMsal } from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { darkTheme as theme } from '../../configs/theme';

function WelcomeName() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState('');

  useEffect(() => {
    if (account && account.name) {
      setName(account.name.split(' ')[0]);
    } else {
      setName('');
    }
  }, [account]);

  if (name) {
    return (
      <Typography variant="h1" sx={{ lineHeight: '40px', color:theme.palette.primary.light}}>
        Welcome, {name}
      </Typography>
    );
  }
  return null;
}

export default WelcomeName;
